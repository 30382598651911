import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const styles = {
  textArea: {
    fontFamily: 'Roboto Mono'
  },
  title: {
    fontFamily: 'Roboto Mono'
  },
  clearButton: {
    position: 'absolute',
    top: 5,
    right: 0,
    padding: 0,
    width: 24,
    height: 24
  }
};

class TextInputPane extends Component {
  static propTypes = {
    left: bool,
    onChangeTitle: func,
    onChangeClip: func,
    classes: object,
    title: string,
    clip: string,
    theme: object
  };

  render() {
    const { left, theme, onChangeTitle, title, classes, clip, onChangeClip } = this.props;

    return (
      <div
        style={{
          width: left ? 'calc(50% - 20px)' : '50%'
        }}
      >
        <Paper
          style={{
            padding: '5px 10px',
            backgroundColor: '#F5F5F5',
            marginBottom: 15,
            ...theme.palette.clipBackground
          }}
        >
          <TextField
            id={`${left ? 'left' : 'right'}-clip-title-input`}
            placeholder="Title (Optional)"
            style={{ width: '100%' }}
            onChange={e => onChangeTitle(e.target.value)}
            value={title}
            InputProps={{ classes: { root: classes.title } }}
          />
        </Paper>
        <Paper
          style={{
            padding: '5px 10px',
            backgroundColor: '#F5F5F5',
            ...theme.palette.clipBackground
          }}
        >
          <div style={{ position: 'relative' }}>
            <TextField
              id={`${left ? 'left' : 'right'}-clip-input`}
              placeholder="Enter or paste text here."
              multiline
              rows={5}
              rowsMax={10}
              style={{ width: '100%' }}
              InputProps={{ classes: { root: classes.textArea } }}
              value={clip}
              onChange={e => onChangeClip(e.target.value)}
            />
            <IconButton
              id={`${left ? 'left' : 'right'}-clip-clear-button`}
              onClick={() => onChangeClip('')}
              className={classes.clearButton}
            >
              <Icon>clear</Icon>
            </IconButton>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withTheme()(withStyles(styles)(TextInputPane));
