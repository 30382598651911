import React from 'react';
import { string, func } from 'prop-types';
import TextField from '@material-ui/core/TextField';

const UrlInput = props => {
  const { id, value, hintText, onChange, error } = props;

  return (
    <TextField
      error={!!error}
      id={id}
      label={hintText}
      helperText={error || ''}
      style={{ width: '100%', marginTop: 10 }}
      value={value}
      onChange={onChange}
    />
  );
};

UrlInput.propTypes = {
  id: string,
  value: string,
  hintText: string,
  onChange: func,
  error: string
};

export default UrlInput;
