import React, { Component } from 'react';
import { bool, object, func, string } from 'prop-types';
import { Amplitude } from '@amplitude/react-amplitude';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import ReactTooltip from 'react-tooltip';

import CheckoutButton from '../CheckoutButton';

const styles = theme => ({
  dialogPaper: {
    width: 600,
    maxWidth: 600,
    overflow: 'visible'
  },
  formLabel: {
    marginBottom: 10
  },
  radioControl: {
    height: 40
  },
  checkBoxContainer: {
    marginRight: 0
  },
  checkBox: {
    height: 40
  },
  disclaimerText: {
    fontSize: 14,
    color: theme.palette.disclaimerTextColor,
    margin: '10px 0'
  },
  termsOfServiceForm: {
    display: 'flex',
    alignItems: 'center'
  },
  termsOfServiceText: {
    fontSize: 14,
    color: theme.palette.disclaimerTextColor
  },
  termsOfServiceLink: {
    cursor: 'pointer'
  }
});

class CheckoutDialog extends Component {
  static propTypes = {
    open: bool,
    user: object,
    premiumSource: string,
    closeDialog: func,
    onSubscribe: func,
    openTermsOfService: func,
    classes: object
  };

  state = {
    selectedPriceModel: 'year',
    termsOfServiceChecked: false
  };

  selectPriceModel = event => {
    this.setState({ selectedPriceModel: event.target.value });
  };

  handleChangeTOSChecked = event => {
    this.setState({ termsOfServiceChecked: event.target.checked });
  };

  render() {
    const { open, user, closeDialog, onSubscribe, openTermsOfService, classes } = this.props;

    const date = new Date().toLocaleDateString();
    const price = this.state.selectedPriceModel === 'year' ? '$19.99 per year' : '$2.99 per month';

    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Checkout</DialogTitle>
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend" focused={false} className={classes.formLabel}>
              <Typography>Choose a subscription plan:</Typography>
            </FormLabel>
            <RadioGroup
              name="subscription"
              value={this.state.selectedPriceModel}
              onChange={this.selectPriceModel}
            >
              <FormControlLabel
                value="year"
                control={<Radio color="primary" />}
                label="Yearly ($19.99 / year)"
                className={classes.radioControl}
              />
              <FormControlLabel
                value="month"
                control={<Radio color="primary" />}
                label="Monthly ($2.99 / month)"
                className={classes.radioControl}
              />
            </RadioGroup>
          </FormControl>
          <div className={classes.disclaimerText}>
            Your <span>{`${this.state.selectedPriceModel}ly`}</span> DiffNow Subscription will start
            on&nbsp;
            <span>{date}</span>. You will be billed <b>{price}</b> starting <span>{date}</span>.
            Your subscription will continue until you cancel it. You may cancel your subscription
            anytime by logging in to your account and navigating to the Manage Premium Subscription
            dialog from the user menu.
          </div>
          <div className={classes.termsOfServiceForm}>
            <FormControlLabel
              className={classes.checkBoxContainer}
              control={
                <Checkbox
                  className={classes.checkBox}
                  color="primary"
                  checked={this.state.termsOfServiceChecked}
                  onChange={this.handleChangeTOSChecked}
                  value="termsOfServiceChecked"
                />
              }
            />
            <div className={classes.termsOfServiceText}>
              I agree to the above and the{' '}
              <u onClick={openTermsOfService} className={classes.termsOfServiceLink}>
                DiffNow Terms of Service
              </u>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button key="cancel" variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Amplitude>
            {({ logEvent }) => (
              <CheckoutButton
                key="checkout"
                isPaymentMethodUpdate={false}
                disabled={!this.state.termsOfServiceChecked}
                subscriptionType={this.state.selectedPriceModel}
                email={user ? user.email : ''}
                onCheckout={token => {
                  logEvent('PURCHASE_SUBSCRIPTION', {
                    source: this.props.premiumSource,
                    type: this.state.selectedPriceModel
                  });
                  onSubscribe(
                    token,
                    this.state.selectedPriceModel === 'year'
                      ? 'diffnow-yearly-plan'
                      : 'diffnow-monthly-plan-299',
                    this.props.premiumSource
                  );
                }}
              >
                {!(user ? user.email : '') && <ReactTooltip id="not-logged-in-tooltip-checkout" />}
                <div
                  data-tip="You must be logged in to use purchase a Premium subscription"
                  data-for="not-logged-in-tooltip-checkout"
                >
                  <Button
                    color="secondary"
                    variant="raised"
                    style={{ color: 'black', marginLeft: 10 }}
                    disabled={!this.state.termsOfServiceChecked}
                  >
                    Checkout
                    <Icon style={{ marginLeft: 10 }}>shopping_cart</Icon>
                  </Button>
                </div>
              </CheckoutButton>
            )}
          </Amplitude>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(CheckoutDialog);
