import React, { Component, Fragment } from 'react';
import { bool, func, string, array, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import find from 'lodash/find';
import ReactTooltip from 'react-tooltip';

import { setCurrentProfile, setUserOptions } from '../../util/options';
import CompareOptions from '../options/CompareOptions';
import ResultsOptions from '../options/ResultsOptions';
import ColorOptions from '../options/ColorOptions';
import PluginOptions from '../options/PluginOptions';

import DeleteProfileDialog from './DeleteProfileDialog';
import RecomparePromptDialog from './RecomparePromptDialog';
import SaveProfileDialog from './SaveProfileDialog';
import WordWrapWarningDialog from './WordWrapWarningDialog';

const styles = theme => ({
  dialogPaper: {
    maxHeight: 800,
    width: 840,
    maxWidth: 840,
    overflow: 'visible'
  },
  titleBar: {
    backgroundColor: theme.palette.optionsDialog.titleBarColor
  },
  tabWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8'
  },
  tabsIndicator: {
    backgroundColor: '#1890ff'
  },
  tabRoot: {
    textTransform: 'initial',
    width: `${100 / 4}%`,
    height: 48,
    minHeight: 48,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: 700
    },
    '&:focus': {
      color: '#40a9ff'
    }
  },
  tabSelected: {}
});

class OptionsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
      renderId: 0,
      saveProfileDialogOpen: false,
      deleteProfileDialogOpen: false,
      recompareDialogOpen: false,
      profileToDelete: ''
    };
  }

  static propTypes = {
    open: bool,
    isPremium: bool,
    shouldPromptToRecompare: bool,
    closeDialog: func,
    sessionToken: string,
    optionsProfiles: array,
    options: object,
    newOptions: object,
    defaultOptions: object,
    classes: object,
    nightModeEnabled: bool,

    onProfileSelect: func,
    onProfileDelete: func,
    onProfileSaveAs: func,

    setNewOption: func,
    onSaveNewOptions: func,
    onResetNewOptions: func,
    onRecompare: func,
    openWhyPremiumDialog: func
  };

  saveAndCloseDialog = () => {
    const {
      defaultOptions,
      newOptions,
      options,
      sessionToken,
      shouldPromptToRecompare,
      onSaveNewOptions
    } = this.props;

    onSaveNewOptions();
    setUserOptions(
      sessionToken,
      Object.assign({ name: this.selectedProfile }, options, newOptions),
      defaultOptions
    );

    if (shouldPromptToRecompare && Object.keys(newOptions).length > 0) {
      this.toggleRecomparePrompt(true);
    }

    this.closeDialog();
  };

  closeDialog = () => {
    this.props.onResetNewOptions();
    this.props.closeDialog();
  };

  openSaveProfileDialog = () => {
    this.setState({
      saveProfileDialogOpen: true
    });
  };

  toggleRecomparePrompt = isOpen => {
    this.setState({
      recompareDialogOpen: isOpen
    });
  };

  saveProfileAs = profile => {
    this.props.onSaveNewOptions();
    setUserOptions(
      this.props.sessionToken,
      Object.assign({}, Object.assign({}, this.props.options, this.props.newOptions), {
        name: profile
      }),
      this.props.defaultOptions,
      () => {
        this.props.onProfileSaveAs(profile);
      }
    );
  };

  selectProfile = e => {
    setCurrentProfile(this.props.sessionToken, e.target.value, this.props.onProfileSelect);
  };

  setOption = (option, value) => {
    this.props.setNewOption({ [option]: value });
    this.setState(prevState => ({ renderId: prevState.renderId + 1 }));
  };

  resetOptions = options => {
    const { defaultOptions, setNewOption } = this.props;
    options.forEach(option => {
      setNewOption({ [option]: defaultOptions[option] });
    });
    this.setState(prevState => ({ renderId: prevState.renderId + 1 }));
  };

  resetAllOptions = () => {
    this.props.setNewOption(this.props.defaultOptions);
  };

  onDeleteClick = (e, profile) => {
    this.setState({
      deleteProfileDialogOpen: true,
      profileToDelete: profile
    });
  };

  forceWordWrap = () => {
    this.resetOptions(['ico', 'ipm', 'icr']);
  };

  keepIgnoreOptions = () => {
    this.resetOptions(['uww']);
  };

  get options() {
    return Object.assign({}, this.props.options, this.props.newOptions);
  }

  get selectedProfile() {
    const currentProfile = find(this.props.optionsProfiles, ['current_profile', 1]);
    return currentProfile ? currentProfile.options_name : 'Default Profile';
  }

  get profilesList() {
    const { optionsProfiles } = this.props;
    return optionsProfiles.map((profile, index) => (
      <MenuItem key={index} value={profile.options_name}>
        {profile.options_name !== 'Default Profile' ? (
          <Icon
            style={{ marginRight: 10 }}
            onClick={e => this.onDeleteClick(e, profile.options_name)}
          >
            close
          </Icon>
        ) : (
          <div style={{ width: 34 }} />
        )}
        {profile.options_name}
      </MenuItem>
    ));
  }

  get shouldShowWordWrapWarning() {
    const { ico, ipm, icr, uww } = this.options;
    return uww && (ico || ipm || icr);
  }

  get styles() {
    return {
      actions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      },
      tab: {
        flexDirection: 'row',
        height: 48
      },
      profileOptions: {
        display: 'flex',
        alignItems: 'center'
      },
      profileOptionsHeader: {
        fontWeight: 700
      }
    };
  }

  get actions() {
    return (
      <div style={this.styles.actions}>
        <div style={this.styles.profileOptions}>
          {!this.props.isPremium && <ReactTooltip id="options-profiles-tooltip-dialog" />}
          <div
            data-tip="Upgrade to DiffNow Premium to use options profiles"
            data-for="options-profiles-tooltip-dialog"
          >
            <FormControl id="profile-picker-form">
              <InputLabel htmlFor="profile-picker">Options Profile</InputLabel>
              <Select
                disabled={!this.props.isPremium}
                value={this.selectedProfile}
                onChange={this.selectProfile}
                style={{ width: 200 }}
                inputProps={{
                  id: 'profile-picker'
                }}
              >
                {this.profilesList}
              </Select>
            </FormControl>
          </div>
          <Button
            variant="outlined"
            onClick={this.openSaveProfileDialog}
            color="primary"
            style={{ marginLeft: 10 }}
          >
            Save As
          </Button>
        </div>
        <div>
          <Button variant="outlined" onClick={this.resetAllOptions} style={{ marginRight: 10 }}>
            Reset All
          </Button>
          <Button variant="outlined" onClick={this.closeDialog} style={{ marginRight: 10 }}>
            Cancel
          </Button>
          <Button variant="raised" color="primary" onClick={this.saveAndCloseDialog}>
            OK
          </Button>
        </div>
      </div>
    );
  }

  render() {
    const tabClasses = {
      wrapper: this.props.classes.tabWrapper,
      root: this.props.classes.tabRoot,
      selected: this.props.classes.tabSelected
    };

    return (
      <Fragment>
        <Dialog open={this.props.open} classes={{ paper: this.props.classes.dialogPaper }}>
          <DialogTitle classes={{ root: this.props.classes.titleBar }}>Options</DialogTitle>
          <DialogContent style={{ padding: 0, overflowY: 'visible' }}>
            <Tabs
              value={this.state.selectedTab}
              onChange={(event, newTab) => this.setState({ selectedTab: newTab })}
              indicatorColor="primary"
              classes={{ root: this.props.classes.tabsRoot }}
            >
              <Tab label="Compare" icon={<Icon>compare</Icon>} classes={tabClasses} />
              <Tab label="Results" icon={<Icon>assessment</Icon>} classes={tabClasses} />
              <Tab label="Colors" icon={<Icon>color_lens</Icon>} classes={tabClasses} />
              <Tab
                label="Plug-ins"
                icon={<Icon>settings_input_component</Icon>}
                classes={tabClasses}
              />
            </Tabs>
            {this.state.selectedTab === 0 && (
              <CompareOptions
                isPremium={this.props.isPremium}
                openWhyPremiumDialog={this.props.openWhyPremiumDialog}
                options={this.options}
                setOption={this.setOption}
                renderId={this.state.renderId}
              />
            )}
            {this.state.selectedTab === 1 && (
              <ResultsOptions
                isPremium={this.props.isPremium}
                openWhyPremiumDialog={this.props.openWhyPremiumDialog}
                options={this.options}
                setOption={this.setOption}
                renderId={this.state.renderId}
              />
            )}
            {this.state.selectedTab === 2 && (
              <ColorOptions
                options={this.options}
                setOption={this.setOption}
                renderId={this.state.renderId}
                nightModeEnabled={this.props.nightModeEnabled}
              />
            )}
            {this.state.selectedTab === 3 && (
              <PluginOptions
                isPremium={this.props.isPremium}
                openWhyPremiumDialog={this.props.openWhyPremiumDialog}
                options={this.options}
                setOption={this.setOption}
                renderId={this.state.renderId}
              />
            )}
          </DialogContent>
          <DialogActions>{this.actions}</DialogActions>
        </Dialog>

        <SaveProfileDialog
          open={this.state.saveProfileDialogOpen}
          onProfileSaveAs={this.saveProfileAs}
          closeSaveProfileDialog={() => {
            this.setState({ saveProfileDialogOpen: false });
          }}
        />
        <DeleteProfileDialog
          open={this.state.deleteProfileDialogOpen}
          onProfileDelete={this.props.onProfileDelete}
          profileToDelete={this.state.profileToDelete}
          closeDeleteProfileDialog={() => {
            this.setState({ deleteProfileDialogOpen: false });
          }}
        />
        <WordWrapWarningDialog
          open={this.props.open && !!this.shouldShowWordWrapWarning}
          onForceWordWrap={this.forceWordWrap}
          onKeepIgnoreOptions={this.keepIgnoreOptions}
        />
        <RecomparePromptDialog
          open={this.state.recompareDialogOpen}
          handleClose={() => {
            this.toggleRecomparePrompt(false);
          }}
          onRecompare={this.props.onRecompare}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(OptionsDialog);
