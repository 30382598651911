import React from 'react';
import PropTypes, { string, func, bool } from 'prop-types';
import StripeCheckout from 'react-stripe-checkout';

const CheckoutButton = ({
  isPaymentMethodUpdate,
  subscriptionType,
  email,
  onCheckout,
  disabled,
  children
}) => (
  <StripeCheckout
    panelLabel={isPaymentMethodUpdate ? 'Update Credit Card' : undefined}
    name="PrestoSoft LLC"
    description={`DiffNow Premium ${
      subscriptionType === 'year' ? 'Yearly' : 'Monthly'
    } Subscription`}
    amount={isPaymentMethodUpdate ? undefined : subscriptionType === 'year' ? 1999 : 299}
    currency="USD"
    stripeKey="pk_live_fjt38vvb2vHSRlE3wYsYtdUG"
    // stripeKey="pk_test_VwDUn2Q8sKxkDLSInAtEpxL7"
    email={email}
    billingAddress
    token={onCheckout}
    triggerEvent="onClick"
    disabled={disabled}
  >
    {children}
  </StripeCheckout>
);

CheckoutButton.propTypes = {
  isPaymentMethodUpdate: bool,
  subscriptionType: string,
  email: string,
  onCheckout: func,
  disabled: bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

export default CheckoutButton;
