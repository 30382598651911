import React, { Component } from 'react';
import { bool, string, func } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { changePasswordForReset } from '../../util/user';

export default class ChangePasswordForResetDialog extends Component {
  state = {
    newPassword: '',
    newPasswordConfirm: '',
    error: null
  };

  static propTypes = {
    open: bool,
    emailAddress: string,
    confirmationKey: string,
    closeDialog: func
  };

  resetState = () => {
    this.setState({
      newPassword: '',
      newPasswordConfirm: '',
      error: null
    });
  };

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      this.validateNewPassword
    );
  };

  get isPasswordTooShort() {
    return this.state.newPassword.length < 6;
  }

  get submitDisabled() {
    return this.isPasswordTooShort || this.state.newPassword !== this.state.newPasswordConfirm;
  }

  validateNewPassword = () => {
    if (this.state.newPassword !== this.state.newPasswordConfirm) {
      this.setState({ error: 'Your passwords must match!' });
    } else {
      this.setState({ error: null });
    }
  };

  handleResetPassword = () => {
    const { newPassword } = this.state;
    const { emailAddress, confirmationKey } = this.props;

    changePasswordForReset(
      emailAddress,
      confirmationKey,
      newPassword,
      () => {
        this.props.closeDialog();
        this.resetState();
      },
      error => {
        this.setState({ error: error });
      }
    );
  };

  render() {
    const { open, closeDialog } = this.props;

    return (
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <TextField
            error={this.isPasswordTooShort}
            helperText={
              this.isPasswordTooShort ? 'Your password must be at least 6 characters long.' : ''
            }
            type="password"
            id="newPassword"
            label="New password"
            value={this.state.newPassword}
            onChange={this.handleChange('newPassword')}
            margin="dense"
            fullWidth
          />
          <TextField
            type="password"
            id="newPasswordConfirm"
            label="Confirm new password"
            value={this.state.newPasswordConfirm}
            onChange={this.handleChange('newPasswordConfirm')}
            margin="dense"
            fullWidth
          />
          {this.state.error && (
            <div style={{ color: 'red', marginTop: 10, fontSize: 12 }}>{this.state.error}</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.resetState();
              closeDialog();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handleResetPassword}
            disabled={this.submitDisabled}
          >
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
