import React from 'react';
import { string, func, bool, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    zIndex: 1500
  }
};

const ConfirmDeleteReportDialog = ({
  shortUrl,
  reportTitle,
  deleteReport,
  handleClose,
  open,
  classes
}) => (
  <Dialog open={open} onClose={handleClose} className={classes.root}>
    <DialogTitle>Confirm Delete Report</DialogTitle>
    <DialogContent>
      <DialogContentText>{`Are you sure you want to delete "${reportTitle}"`}?</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Cancel</Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          deleteReport(shortUrl);
          handleClose();
        }}
      >
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDeleteReportDialog.propTypes = {
  shortUrl: string,
  reportTitle: string,
  deleteReport: func,
  handleClose: func,
  open: bool,
  classes: object
};

export default withStyles(styles)(ConfirmDeleteReportDialog);
