import React, { Component } from 'react';
import { object, func, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ColorPicker from './ColorPicker';
import ColorPickerCheckbox from './ColorPickerCheckbox';

const styles = theme => ({
  borderlessCell: {
    border: 0
  },
  rowHeader: {
    border: 0,
    color: theme.palette.primary.optionsText,
    padding: '8px 0 !important'
  },
  cornerCell: {
    width: 200,
    paddingBottom: '10px !important',
    paddingTop: '0 !important'
  }
});

class ColorOptions extends Component {
  static propTypes = {
    options: object,
    setOption: func,
    classes: object,
    nightModeEnabled: bool
  };

  renderTableRowHeader = label => (
    <TableRow>
      <TableCell
        colSpan={5}
        className="color-section"
        classes={{ root: this.props.classes.rowHeader }}
      >
        <span>{label}</span>
      </TableCell>
    </TableRow>
  );

  renderTableRow = (optionKey, label) => {
    const { options, setOption, classes, nightModeEnabled } = this.props;
    optionKey = nightModeEnabled ? `${optionKey}_d` : optionKey;
    const option = options[optionKey];
    let textDecoration = 'none';

    if (option.u && option.s) {
      textDecoration = 'underline line-through';
    } else if (option.u) {
      textDecoration = 'underline';
    } else if (option.s) {
      textDecoration = 'line-through';
    }

    return (
      <TableRow>
        <TableCell
          classes={{ root: classes.borderlessCell }}
          style={{ backgroundColor: `rgb(${option.bc})` }}
        >
          <div
            style={{
              color: `rgb(${option.tc})`,
              textDecoration: textDecoration
            }}
          >{`${label}: `}</div>
        </TableCell>
        <TableCell classes={{ root: classes.borderlessCell }}>
          <ColorPicker option={option} color="tc" optionKey={optionKey} setOption={setOption} />
        </TableCell>
        <TableCell classes={{ root: classes.borderlessCell }}>
          <ColorPicker option={option} color="bc" optionKey={optionKey} setOption={setOption} />
        </TableCell>
        <TableCell classes={{ root: classes.borderlessCell }}>
          <ColorPickerCheckbox
            option={option}
            textDecoration="u"
            optionKey={optionKey}
            setOption={setOption}
          />
        </TableCell>
        <TableCell classes={{ root: classes.borderlessCell }}>
          <ColorPickerCheckbox
            option={option}
            textDecoration="s"
            optionKey={optionKey}
            setOption={setOption}
          />
        </TableCell>
      </TableRow>
    );
  };

  get styles() {
    return {
      optionsBody: {
        padding: 20,
        height: 460
      }
    };
  }

  render() {
    return (
      <div style={this.styles.optionsBody}>
        <Table className="color-picker">
          <TableHead>
            <TableRow>
              <TableCell classes={{ root: this.props.classes.cornerCell }}>
                Editing <b>{this.props.nightModeEnabled ? 'Night Mode' : 'Standard'}</b> Colors
              </TableCell>
              <TableCell classes={{ root: this.props.classes.rowHeader }}>Text color</TableCell>
              <TableCell classes={{ root: this.props.classes.rowHeader }}>
                Background color
              </TableCell>
              <TableCell classes={{ root: this.props.classes.rowHeader }}>Underline</TableCell>
              <TableCell classes={{ root: this.props.classes.rowHeader }}>Strikethrough</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderTableRowHeader('For file and archive comparison')}
            {this.renderTableRow('ice', 'Identical items')}
            {this.renderTableRow('ace', 'Added items')}
            {this.renderTableRow('dce', 'Deleted items')}
            {this.renderTableRow('cce', 'Changed items')}
            {this.renderTableRowHeader('For file comparison only')}
            {this.renderTableRow('ccc', 'Changed text in changed lines')}
            {this.renderTableRow('gce', 'Ignored text')}
            {this.renderTableRowHeader('For archive comparison only')}
            {this.renderTableRow('nce', 'Newer items')}
            {this.renderTableRow('oce', 'Older items')}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(ColorOptions);
