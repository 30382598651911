import React, { Component } from 'react';
import { object, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReactTooltip from 'react-tooltip';

import { optionsTooltips } from '../../util/constants';
import grippyImage from '../../grippy.png';

const styles = {
  formControlLabelRoot: {
    marginLeft: 0
  },
  checkBoxRoot: {
    width: 16,
    height: 16,
    margin: '8px 16px'
  }
};

class DraggableCheckbox extends Component {
  static propTypes = {
    item: object,
    dragHandle: func,
    classes: object,
    commonProps: object
  };

  render() {
    const { item, dragHandle, classes, commonProps } = this.props;
    const { options, setOption, disabled } = commonProps;

    const innerStyles = {
      dragHandle: {
        cursor: 'move',
        width: 20,
        height: 16,
        background: `url(${grippyImage}) 30% 50% no-repeat no-repeat`
      }
    };

    return (
      <div className="draggable-checkbox" style={{ display: 'flex' }}>
        {dragHandle(<div className="dragHandle" style={innerStyles.dragHandle} />)}
        <ReactTooltip id={`${item.key}-tooltip`} multiline />
        <FormControlLabel
          classes={{ root: classes.formControlLabelRoot }}
          control={
            <Checkbox
              color="primary"
              disabled={disabled}
              checked={options[item.key]}
              className={classes.checkBoxRoot}
              onChange={event => {
                setOption(item.key, event.target.checked);
              }}
            />
          }
          label={
            <u data-tip={optionsTooltips[item.key]} data-for={`${item.key}-tooltip`}>
              {item.label}
            </u>
          }
        />
      </div>
    );
  }
}

export default withStyles(styles)(DraggableCheckbox);
