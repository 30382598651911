import React, { Component } from 'react';
import { func, string, object, number } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import { Amplitude } from '@amplitude/react-amplitude';

import { COMPARE_FILES, COMPARE_PANE_TRANSITION_MS } from '../util/constants';
import { uploadFile } from '../util/compare';
import { isPremium } from '../util/common';
import * as actions from '../actions/global';
import FileInput from '../components/comparison/FileInput';
import CompareButton from '../components/comparison/CompareButton';

function mapStateToProps(state) {
  return {
    theme: state.global.theme,
    user: state.global.user,
    clientId: state.global.clientId,
    options: state.global.options,
    comparisonCount: state.global.comparisonCount,
    comparisonStartDate: state.global.comparisonStartDate,
    configuration: state.global.configuration,
    firstFileStatus: state.global.firstFileStatus,
    firstFileName: state.global.firstFileName,
    firstFileUploadedName: state.global.firstFileUploadedName,
    secondFileStatus: state.global.secondFileStatus,
    secondFileName: state.global.secondFileName,
    secondFileUploadedName: state.global.secondFileUploadedName,
    collapsedTabIndex: state.global.collapsedTabIndex
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSetStatus(status) {
      dispatch(actions.setStatus(status));
    },
    onSetFirstFile(firstFileStatus, firstFileName, firstFileUploadedName) {
      dispatch(actions.setFirstFile(firstFileStatus, firstFileName, firstFileUploadedName));
    },
    onSetSecondFile(secondFileStatus, secondFileName, secondFileUploadedName) {
      dispatch(actions.setSecondFile(secondFileStatus, secondFileName, secondFileUploadedName));
    },
    onSetCollapsedTabIndex(newCollapsedTabIndex) {
      dispatch(actions.setCollapsedTabIndex(newCollapsedTabIndex));
    }
  };
}

export class CompareFiles extends Component {
  static propTypes = {
    theme: string,
    clientId: string,
    onCompare: func,
    onSetStatus: func,
    onSetFirstFile: func,
    onSetSecondFile: func,
    onSetCollapsedTabIndex: func,
    options: object,
    user: object,
    configuration: object,
    firstFileStatus: string,
    firstFileName: string,
    firstFileUploadedName: string,
    secondFileStatus: string,
    secondFileName: string,
    secondFileUploadedName: string,
    collapsedTabIndex: number,
    openWhyPremiumDialog: func
  };

  constructor(props) {
    super(props);

    const {
      firstFileStatus,
      firstFileName,
      firstFileUploadedName,
      secondFileStatus,
      secondFileName,
      secondFileUploadedName
    } = this.props;

    this.state = {
      open: false,
      firstFileStatus,
      firstFileName,
      firstFileUploadedName,
      secondFileStatus,
      secondFileName,
      secondFileUploadedName
    };
  }

  static getDerivedStateFromProps = (props, state) => ({
    ...state,
    open: props.collapsedTabIndex === null
  });

  componentDidMount() {
    this.props.onSetCollapsedTabIndex(null);
  }

  setFileStatus = (status, index, filename) => {
    if (index === 0) {
      this.setFirstFileStatus(status, filename);
    } else {
      this.setSecondFileStatus(status, filename);
    }
  };

  setFirstFileStatus = (status, filename) => {
    this.setState(prevState => ({
      firstFileStatus: status,
      firstFileName: filename ? filename : prevState.firstFileName
    }));
  };

  setSecondFileStatus = (status, filename) => {
    this.setState(prevState => ({
      secondFileStatus: status,
      secondFileName: filename ? filename : prevState.secondFileName
    }));
  };

  uploadFile = async (file, index) => {
    const { user, configuration } = this.props;

    if (file === undefined) {
      return;
    }

    this.setFileStatus('uploading', index, file.name);

    const sessionToken = user ? user.sessionToken : null;
    const fileSizeKB = Math.round(file.size / 1024);
    const maxSizeKB = isPremium(user) ? configuration.maxSizePremiumKB : configuration.maxSizeKB;

    if (fileSizeKB <= maxSizeKB) {
      try {
        const { uploadedFileName } = await uploadFile(file, index, sessionToken);
        this.onUploadSuccess(file, uploadedFileName, index);
      } catch (error) {
        this.onUploadError(error);
      }
    } else {
      this.handleFileTooLarge(file.name, fileSizeKB, maxSizeKB, index);
    }
  };

  handleFileTooLarge = (fileName, fileSizeKB, maxSizeKB, index) => {
    const { user, configuration } = this.props;

    let tooLargeError = `Your file ${fileName} of size ${fileSizeKB} KB exceeds the max size of ${maxSizeKB} KB.`;

    if (!isPremium(user)) {
      tooLargeError = `${tooLargeError} Upgrade to DiffNow Premium to upload files of size up to ${
        configuration.maxSizePremiumKB
      } KB.`;
    }

    this.onUploadError(tooLargeError, index);
  };

  onUploadSuccess = (file, uploadedFileName, index) => {
    const { firstFileName, secondFileName } = this.state;
    const { onSetStatus, onSetFirstFile, onSetSecondFile } = this.props;

    this.setFileStatus('uploaded', index, null);
    onSetStatus({
      message: `Successfully uploaded ${index === 0 ? 'first' : 'second'} file (${
        file.name
      }) of size ${file.size} bytes`,
      type: 'info'
    });

    if (index === 0) {
      this.setState({ firstFileUploadedName: uploadedFileName });
      onSetFirstFile('uploaded', firstFileName, uploadedFileName);
    } else {
      this.setState({ secondFileUploadedName: uploadedFileName });
      onSetSecondFile('uploaded', secondFileName, uploadedFileName);
    }
  };

  onUploadError = (error, index) => {
    const { onSetStatus, onSetFirstFile, onSetSecondFile } = this.props;

    this.setFileStatus('error', index, null);
    onSetStatus({
      message: error,
      type: 'error'
    });

    if (index === 0) {
      onSetFirstFile('not-uploaded', '', '');
    } else {
      onSetSecondFile('not-uploaded', '', '');
    }
  };

  onUploadFirstFile = file => {
    this.uploadFile(file, 0);
  };

  onUploadSecondFile = file => {
    this.uploadFile(file, 1);
  };

  compareFiles = async () => {
    this.props.onCompare(COMPARE_FILES, this.getComparisonPayload());
  };

  getComparisonPayload = () => {
    const { user, clientId, options, theme } = this.props;
    const {
      firstFileName,
      firstFileUploadedName,
      secondFileName,
      secondFileUploadedName
    } = this.state;

    const sessionToken = user ? user.sessionToken : null;

    return {
      uploadedFileName1: firstFileUploadedName,
      originalFileName1: firstFileName,
      uploadedFileName2: secondFileUploadedName,
      originalFileName2: secondFileName,
      sessionId: clientId,
      sessionToken,
      optionsJson: options,
      darkMode: theme === 'dark'
    };
  };

  get styles() {
    return {
      outer: {
        padding: 20,
        paddingBottom: 12
      },
      contents: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 168
      },
      topLabel: {
        fontSize: 14,
        color: '#777777'
      },
      bottomLabel: {
        fontSize: 12,
        color: '#999999'
      },
      compareButtonContainer: {
        position: 'relative',
        display: 'flex',
        marginTop: 15,
        justifyContent: 'center',
        height: 50
      }
    };
  }

  render() {
    const { openWhyPremiumDialog } = this.props;

    const isUploadDisabled = isEmpty(this.props.configuration);
    const isCompareDisabled =
      this.state.firstFileStatus !== 'uploaded' || this.state.secondFileStatus !== 'uploaded';

    return (
      <div>
        <Paper style={this.state.open ? this.styles.outer : {}}>
          <Collapse
            in={this.state.open}
            timeout={COMPARE_PANE_TRANSITION_MS}
            style={this.state.open ? {} : { visibility: 'hidden' }}
          >
            <div style={this.styles.contents}>
              <div style={this.styles.topLabel}>
                Select or drag-and-drop the files you want to compare below.
              </div>

              <FileInput
                label="Upload First File"
                disabled={isUploadDisabled}
                onUpload={this.onUploadFirstFile}
                fileName={this.state.firstFileName}
                status={this.state.firstFileStatus}
              />

              <FileInput
                label="Upload Second File"
                disabled={isUploadDisabled}
                onUpload={this.onUploadSecondFile}
                fileName={this.state.secondFileName}
                status={this.state.secondFileStatus}
              />

              <div style={this.styles.bottomLabel}>
                2048 KB limit (8192 KB for{' '}
                <a href="#why-premium" onClick={openWhyPremiumDialog}>
                  premium users
                </a>
                )
              </div>
            </div>

            <div style={this.styles.compareButtonContainer}>
              <Amplitude>
                {({ logEvent }) => (
                  <CompareButton
                    disabled={isCompareDisabled}
                    onClick={() => {
                      this.compareFiles();
                      logEvent('START_COMPARISON', { type: 'FILES' });
                    }}
                  />
                )}
              </Amplitude>
            </div>
          </Collapse>
        </Paper>
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompareFiles)
);
