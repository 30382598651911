export let DIFFNOW_URL = '';
if (process.env.REACT_APP_ENV === 'remote') {
  DIFFNOW_URL = 'https://diffnow.com';
} else if (process.env.REACT_APP_ENV === 'local') {
  DIFFNOW_URL = 'http://localhost/DiffNowApplication';
}
export const DIFFNOW_API_URL = `${DIFFNOW_URL}/api`;

export const SUCCESS_MESSAGE_DELAY_MS = 1000;
export const COMPARE_PANE_TRANSITION_MS = 250;

export const IS_CURRENT_PROFILE = 1;

export const COMPARE_CLIPS = 0;
export const COMPARE_FILES = 1;
export const COMPARE_URLS = 2;

export const optionsLabels = {
  ica: 'Case',
  ils: 'Linebreak styles',
  ico: 'Comments',
  iwa: 'All',
  iwl: 'Leading',
  iwt: 'Trailing',
  iwc: 'Changes in amount',
  ibl: 'All blank lines',
  ibe: 'Blank lines at the end of a file',
  ilm: 'Lines matching regular expression',
  ilr: 'Line ranges',
  ipm: 'Parts matching regular expression',
  icr: 'Column ranges',
  fbc: 'Force binary comparison',

  hrz: 'Split results vertically',
  vws: 'Show white space',
  sli: 'Show line inspector',
  lcl: 'Line count limit (0 = no limit)',
  www: 'Word wrap width'
};

export const optionsTooltips = {
  ils: 'Ignore linebreak styles: CRLF (DOS/Windows) vs. LF (Unix) vs. CR (Mac).',
  'ignore-whitespace':
    'Ignore white space in lines:' +
    '<ul>' +
    '<li class="ui-corner-left">All space/tab characters</li>' +
    '<li class="ui-corner-left">Only leading space/tabs</li>' +
    '<li class="ui-corner-left">Only trailing space/tabs</li>' +
    '<li class="ui-corner-left">Only changes in amount of space/tabs (e.g. 2 spaces are considered same as 3 tabs).</li>' +
    '</ul>',
  ilr:
    'Use a comma-separated list of inclusive ranges, each of which is either a number or<br>' +
    'a pair of dash-separatednumbers. You can also prefix a range with "1:" or "2:" to apply<br>' +
    'it only to the first or to the second file. Numbers in square brackets count from the<br>' +
    'end of the file. Examples: 1-6 or 1:1-6,2:12,20-[6],[1].',
  icr:
    'Use a comma-separated list of ranges, following the rules for defining ranges of ignored<br>' +
    'lines above. You can also prefix a range with "1:" or "2:" to apply it only to the first or to the<br>' +
    'second file. Numbers in square brackets count from the end of the line. Examples: 1-6 or<br>' +
    '1:1-6,2:12,20-[6],[1].',
  fbc: 'Compare all files as binary and show the results in HEX format.',
  sli:
    'Show the line inspector, a panel that sits underneath the<br>' +
    'comparison panes and displays the currently selected line<br>' +
    'and the matching line in the opposite pane, one on top of the other.',
  lcl:
    'This feature helps limit diff report sizes, as most modern browsers<br>' +
    'cannot handle incredibly large reports. Note that you can drastically<br>' +
    'reduce report size by selecting "Show only differences" on this page.',
  html: '"Beautifies" an HTML file by adding hierarchical indentation.',
  xmlf: '"Beautifies" an XML file by adding hierarchical indentation.',
  xmlc:
    'Converts an XML file into its canonical form, following the Canonical<br>' +
    'XML W3C Standard Recommendation. Canonicalization makes two XML files<br>' +
    "consistently structured, which helps to compare them. It's also recommended<br>" +
    'that you use the Format plugin (see below) along with this for hierarchical<br>' +
    'indentation of XML files.',
  jsnf: '"Beautifies" a JSON file through consistent indentation.',
  jsnc: 'Canonicalizes JSON by outputting the fields of each object with the keys in sorted order.',
  sort: 'This plug-in is useful when compared files represent unsorted lists.',
  inis:
    'Alphabetically sorts all sections and entries in each section, as well as separating sections with a blank line for clarity.',
  pe32: 'Allows comparing Windows executable files by dumping them to text.'
};

export const plugins = [
  {
    label: 'HTML Format (*.html)',
    key: 'html'
  },
  {
    label: 'XML Format (*.xml)',
    key: 'xmlf'
  },
  {
    label: 'XML Canonicalize (*.xml)',
    key: 'xmlc'
  },
  {
    label: 'JSON Format (*.json)',
    key: 'jsnf'
  },
  {
    label: 'JSON Canonicalize (*.json)',
    key: 'jsnc'
  },
  {
    label: 'Sort (*.*)',
    key: 'sort'
  },
  {
    label: 'INI Sorter (*.ini,*.reg)',
    key: 'inis'
  },
  {
    label: 'Dump PE32 (*.exe,*.dll)',
    key: 'pe32'
  }
];

export const defaultOptions = {
  ica: false, // Ignore Case
  ils: true, // Ignore Linebreak Styles
  ico: false, // Ignore Comments
  iwa: true, // Ignore Whitespace All
  iwl: false, // Ignore Whitespace Leading
  iwt: false, // Ignore Whitespace Trailing
  iwc: false, // Ignore Whitespace Changes
  ibl: true, // Ignore All Blank Lines
  ibe: false, // Ignore Blank End
  ilm: '', // Ignore Lines Matching
  ilr: '', // Ignore Line Ranges
  ipm: '', // Ignore Parts Matching
  icr: '', // Ignore Column Ranges
  fbc: false, // Force Binary Comparison

  hrz: true, // Vertical Split
  uvf: 0, // View Filter
  sli: true, // Show Line Inspector
  vws: false, // View White Space
  lcl: 2000, // Line Count Limit
  uww: false, // Use Word Wrap
  www: 80, // Word Wrap Width
  det: 1, // Detail Level

  ice: {
    // Identical Items
    tc: '0, 0, 0',
    bc: '255, 255, 255',
    u: false,
    s: false
  },
  ace: {
    // Added Items
    tc: '128, 0, 0',
    bc: '192, 192, 192',
    u: false,
    s: false
  },
  dce: {
    // Deleted Items
    tc: '0, 0, 255',
    bc: '224, 224, 224',
    u: false,
    s: false
  },
  cce: {
    // Changed Items
    tc: '64, 32, 0',
    bc: '242, 242, 198',
    u: false,
    s: false
  },
  ccc: {
    // Changed Text in Changed Lines
    tc: '255, 15, 135',
    bc: '224, 224, 224',
    u: false,
    s: false
  },
  gce: {
    // Ignored Text
    tc: '0, 128, 0',
    bc: '240, 240, 240',
    u: false,
    s: false
  },
  nce: {
    // Newer Items
    tc: '128, 0, 128',
    bc: '166, 202, 240',
    u: false,
    s: false
  },
  oce: {
    // Older Items
    tc: '255, 0, 0',
    bc: '192, 220, 192',
    u: false,
    s: false
  },
  html: false, // HTML Format
  xmlf: false, // XML Format
  xmlc: false, // XML Canonicalize
  jsnf: false, // JSON Format
  jsnc: false, // JSON Canonicalize
  sort: false, // Sort
  inis: false, // INI Sorter
  pe32: false, // Dump PE32
  html_n: 0, // HTML Format Index
  xmlf_n: 1, // XML Format Index
  xmlc_n: 2, // XML Canonicalize Index
  jsnf_n: 3, // JSON Format Index
  jsnc_n: 4, // JSON Canonicalize Inde
  sort_n: 5, // Sort Index
  inis_n: 6, // INI Sorter Index
  pe32_n: 7 // Dump PE32 Index
};

export const getFeatures = configuration => ({
  standardFeatures: [
    configuration.useMonthlyComparisonLimits
      ? `<b>${configuration.monthlyComparisonCountLimitMax}</b> comparisons a month`
      : `<b>${configuration.comparisonCountLimitNotLoggedInMax}</b> comparisons a day`,
    '<b>Standard</b> comparison queue priority',
    'Reports savable for up to <b>1 year</b>',
    '<b>Ads</b>',
    `<b>${configuration.maxSizeKB} KB</b> file size limit`,
    '<b>2000 line</b> diff report limit',
    '<b>Single</b> options profile',
    'No word wrap',
    'Basic ignore options',
    'No forcing of binary comparison',
    'No plug-in support'
  ],
  premiumFeatures: [
    '<b>Unlimited</b> comparisons',
    '<b>High</b> comparison queue priority',
    'Reports savable <b>indefinitely</b>',
    '<b>No</b> ads',
    `<b>${configuration.maxSizePremiumKB} KB</b> file size limit`,
    '<b>Unlimited</b> diff report size',
    '<b>Multiple</b> options profiles',
    'Customizable word wrap',
    'Advanced ignore options',
    'Ability to force of binary comparison',
    'Support for <b>8</b> advanced plug-ins'
  ]
});

export const docTypes = {
  '': 'Auto Detect',
  bas: 'Basic / Visual Basic',
  bat: 'Batch',
  c: 'C / C++',
  cs: 'C#',
  css: 'CSS',
  for: 'Fortran',
  html: 'HTML',
  java: 'Java',
  js: 'JavaScript',
  json: 'JSON',
  lisp: 'Lisp / Common Lisp',
  pas: 'Pascal / Delphi',
  perl: 'Perl',
  php: 'PHP',
  py: 'Python',
  rb: 'Ruby',
  sql: 'SQL / MySQL',
  tcl: 'TCL',
  tex: 'TeX / LaTeX',
  xml: 'XML'
};

export const DONATE_URL = 'https://www.paypal.me/diffnow';
export const PRESTOSOFT_URL = 'https://www.prestosoft.com';

export const SIGNALR_STATUS = {
  CONNECTING: 0,
  CONNECTED: 1,
  RECONNECTING: 2,
  DISCONNECTED: 4
};

export const AMPLITUDE_KEY = '5d5bffda7e32c4292b3248d1ef502186';
