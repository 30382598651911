import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const styles = {
  dialogPaper: {
    width: 400,
    maxWidth: 400
  }
};

class SaveProfileDialog extends Component {
  state = {
    profileName: ''
  };

  static propTypes = {
    open: bool,
    closeSaveProfileDialog: func,
    onProfileSaveAs: func,
    classes: object
  };

  handleChange = e => {
    this.setState({
      profileName: e.target.value
    });
  };

  render() {
    const { onProfileSaveAs, closeSaveProfileDialog, open, classes } = this.props;
    const actions = [
      <Button
        key="cancel"
        variant="outlined"
        onClick={closeSaveProfileDialog}
        style={{ marginRight: 10 }}
      >
        Cancel
      </Button>,
      <Button
        key="save"
        variant="raised"
        color="primary"
        onClick={() => {
          closeSaveProfileDialog();
          onProfileSaveAs(this.state.profileName);
        }}
        disabled={isEmpty(this.state.profileName)}
      >
        Save
      </Button>
    ];

    return (
      <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Save Profile As</DialogTitle>
        <DialogContent>
          <TextField
            label="New Profile Name"
            id="new-profile-name"
            style={{ width: '100%' }}
            value={this.state.profileName}
            onChange={this.handleChange}
          />
        </DialogContent>
        <DialogActions>{actions}</DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(SaveProfileDialog);
