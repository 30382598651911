export function validateRange(rangeString) {
  function validateValue(input) {
    let returnValue = null;

    if (input.includes('[') || input.includes(']')) {
      returnValue = validateBrackets(input);
      if (returnValue !== null) {
        returnValue = validateNumericValue(input.replace(/\[/g, '').replace(/\]/g, ''));
      }
    } else {
      returnValue = validateNumericValue(input.replace(/\[/g, '').replace(/\]/g, ''));
    }

    return returnValue;
  }

  function validateNumericValue(input) {
    if (isNaN(Number(input))) {
      return `Error: ${input} is not a valid number`;
    } else {
      return null;
    }
  }

  function validateBrackets(input) {
    if (!(input[0] === '[' && input[input.length - 1] === ']')) {
      return `Error: ${input} includes invalid brackets`;
    } else {
      return null;
    }
  }

  if (rangeString.includes(' ')) {
    return 'Error: Ranges cannot contain whitespace';
  }

  let returnValue = null;
  rangeString.split(',').forEach(range => {
    if (range.includes(':')) {
      if (range.split(':')[0] !== '1' && range.split(':')[0] !== '2') {
        returnValue = 'Error: File indicator before ":" must be either 1 or 2';
      }
      if (range.split(':')[1] === '') {
        returnValue = 'Error: Expecting range after ":"';
      }
      range = range.split(':')[1];
    }

    const rangeSplit = range.split('-');
    let validationError = '';

    if ((rangeSplit.length === 1 || rangeSplit[1] === '') && range.includes('-')) {
      returnValue = 'Error: invalid number of arguments';
    } else if (rangeSplit.length === 1 && !range.includes('-')) {
      validationError = validateValue(rangeSplit[0]);
      if (validationError !== null) {
        returnValue = validationError;
      }
    } else {
      const firstNum = rangeSplit[0];
      const secondNum = rangeSplit[1];

      validationError = validateValue(firstNum);
      if (validationError !== null) {
        returnValue = validationError;
      }

      validationError = validateValue(secondNum);
      if (validationError !== null) {
        returnValue = validationError;
      }

      if (
        !firstNum.includes('[') &&
        !secondNum.includes('[') &&
        Number(firstNum) > Number(secondNum)
      ) {
        returnValue = `Error: ${firstNum} is greater than ${secondNum}`;
      } else if (
        firstNum.includes('[') &&
        secondNum.includes('[') &&
        Number(firstNum.replace(/[[\]]/g, '')) < Number(secondNum.replace(/[[\]]/g, ''))
      ) {
        returnValue = `Error: ${firstNum} is less than ${secondNum}`;
      }
    }
  });

  return returnValue;
}

export function validateRegex(regexString) {
  try {
    new RegExp(regexString);
    return null;
  } catch (error) {
    return error.message;
  }
}

export function validateNumber(numberString) {
  if (isNaN(numberString)) {
    return `Error: ${numberString} is not a number`;
  }

  if (numberString === '') {
    return 'Error: Input cannot be empty';
  }

  if (Number(numberString) < 0) {
    return `Error: ${numberString} is not a valid integer greater than or equal to 0`;
  }

  return null;
}
