import axios from 'axios';

import { DIFFNOW_API_URL, SUCCESS_MESSAGE_DELAY_MS, COMPARE_PANE_TRANSITION_MS } from './constants';

const UPLOAD_URL = `${DIFFNOW_API_URL}/upload/uploadFile`;
const COMPARE_URL = `${DIFFNOW_API_URL}/compare`;

export async function uploadFile(file, index, sessionToken) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('index', index);
  formData.append('fileSize', file.size);
  formData.append('sessionToken', sessionToken);

  const postOptions = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };

  try {
    const { output } = (await axios.post(UPLOAD_URL, formData, postOptions)).data;
    return { uploadedFileName: output };
  } catch (error) {
    handleComparisonError(error);
  }
}

export async function compareClips(comparisonPayload) {
  try {
    return (await axios.post(`${COMPARE_URL}/compareClips`, comparisonPayload)).data;
  } catch (error) {
    handleComparisonError(error);
  }
}

export async function compareFiles(comparisonPayload) {
  try {
    return (await axios.post(`${COMPARE_URL}/compareFiles`, comparisonPayload)).data;
  } catch (error) {
    handleComparisonError(error);
  }
}

export async function compareUrls(comparisonPayload) {
  try {
    return (await axios.post(`${COMPARE_URL}/compareUrls`, comparisonPayload)).data;
  } catch (error) {
    handleComparisonError(error);
  }
}

export function executePostComparisonActions(compareProps, pageIndex) {
  setTimeout(() => {
    compareProps.onSetCollapsedTabIndex(pageIndex);
    compareProps.onSetLastComparedTabIndex(pageIndex);
  }, SUCCESS_MESSAGE_DELAY_MS);

  setTimeout(() => {
    compareProps.history.push('/report');
  }, SUCCESS_MESSAGE_DELAY_MS + COMPARE_PANE_TRANSITION_MS);
}

function handleComparisonError(error) {
  if (error.response) {
    throw new Error(error.response.data.output);
  } else {
    throw new Error(error.message);
  }
}
