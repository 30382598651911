import React, { Component } from 'react';
import { string, object, func } from 'prop-types';
import { CompactPicker } from 'react-color';

class ColorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayColorPicker: false
    };
  }

  static propTypes = {
    option: object,
    color: string,
    optionKey: string,
    setOption: func
  };

  handleClick = () => {
    this.setState(prevState => ({
      displayColorPicker: !prevState.displayColorPicker
    }));
  };

  handleClose = () => {
    this.setState({
      displayColorPicker: false
    });
  };

  handleChange = next => {
    const { setOption, option, optionKey, color } = this.props;
    const nextColor = `${next.rgb.r}, ${next.rgb.g}, ${next.rgb.b}`;

    setOption(optionKey, Object.assign({}, option, { [color]: nextColor }));
  };

  get styles() {
    const { option, color } = this.props;

    return {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        border: '1px solid #DDDDDD',
        background: `rgb(${option[color]})`
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
        display: 'inline-block',
        cursor: 'pointer'
      },
      popover: {
        position: 'absolute',
        zIndex: '2'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      }
    };
  }

  get color() {
    const { option, color } = this.props;
    const colorValues = option[color] ? option[color].split(', ') : [0, 0, 0];

    return {
      r: colorValues[0],
      g: colorValues[1],
      b: colorValues[2]
    };
  }

  render() {
    return (
      <div>
        <div style={this.styles.swatch} onClick={this.handleClick}>
          <div style={this.styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={this.styles.popover}>
            <div style={this.styles.cover} onClick={this.handleClose} />
            <CompactPicker color={this.color} onChange={this.handleChange} triangle="hide" />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPicker;
