/* eslint no-restricted-globals: ["off", "location"] */
import React, { Component, Fragment } from 'react';
import { bool, object, func } from 'prop-types';
import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { login, register, resetPassword } from '../../util/user';
import WhyRegisterDialog from '../dialogs/WhyRegisterDialog';

const styles = {
  dialogPaper: {
    width: 500,
    maxWidth: 500
  }
};

class LoginDialog extends Component {
  static propTypes = {
    open: bool,
    closeDialog: func,
    onUserLogin: func,
    openRegisterConfirmDialog: func,
    classes: object
  };

  state = {
    error: null,
    register: false,
    email: '',
    password: '',
    isWhyRegisterDialogOpen: false,
    mailerChecked: false
  };

  get isPasswordTooShort() {
    return this.state.password.length < 6;
  }

  get submitDisabled() {
    return !this.notEmpty([this.state.email, this.state.password]) || this.isPasswordTooShort;
  }

  handleClose = () => {
    this.props.closeDialog();
    this.setState({ register: false });
  };

  onUserLogin = (user, sessionToken) => {
    this.props.onUserLogin(user, sessionToken);
    this.setState({ error: null });
    this.handleClose();
  };

  onUserRegister = email => {
    this.props.openRegisterConfirmDialog(email);
    this.setState({ error: null });
    this.handleClose();
  };

  register = (email, password) => {
    register(email, password, this.state.mailerChecked, this.onUserRegister, errorMessage => {
      this.setState({ error: errorMessage });
    });
  };

  login = (email, password) => {
    login(email, password, this.onUserLogin, errorMessage => {
      this.setState({ error: errorMessage });
    });
  };

  resetPassword = email => {
    resetPassword(
      email,
      () => {
        this.setState({ error: `Password reset email sent to ${email}.` });
      },
      error => {
        this.setState({ error: error });
      }
    );
  };

  handleKeyPress = t => {
    if (t.charCode === 13 && !this.submitDisabled) {
      this.handleSubmit();
    }
  };

  handleClickSwitchMode = () => {
    this.setState(state => ({ register: !state.register }));
  };

  handleClickWhyRegister = () => {
    this.setState({ isWhyRegisterDialogOpen: true });
  };

  closeWhyRegisterDialog = () => {
    this.setState({ isWhyRegisterDialogOpen: false });
  };

  handleClickForgotPassword = () => {
    this.resetPassword(this.state.email);
  };

  handleChangeEmail = e => {
    this.setState({ email: e.target.value });
  };

  handleChangePassword = e => {
    this.setState({ password: e.target.value });
  };

  handleSubmit = () => {
    if (this.state.register) {
      this.register(this.state.email, this.state.password);
    } else {
      this.login(this.state.email, this.state.password);
    }
  };

  handleChangeMailerChecked = e => {
    this.setState({ mailerChecked: e.target.checked });
  };

  notEmpty(fields) {
    return fields.reduce((base, field) => base && field !== '', true);
  }

  renderLoginForm() {
    return (
      <div style={{ position: 'relative' }}>
        <TextField
          value={this.state.email}
          style={{ width: '100%', marginBottom: 12 }}
          label="Email address"
          onKeyPress={this.handleKeyPress}
          onChange={this.handleChangeEmail}
        />
        <TextField
          error={this.isPasswordTooShort && this.state.register}
          helperText={
            this.isPasswordTooShort && this.state.register
              ? 'Your password must be at least 6 characters long.'
              : ''
          }
          value={this.state.password}
          style={{ width: '100%', marginBottom: 12 }}
          label="Password"
          type="password"
          onKeyPress={this.handleKeyPress}
          onChange={this.handleChangePassword}
        />
        {this.state.register && (
          <Fragment>
            <FormControlLabel
              label="Keep me posted with the latest DiffNow news"
              control={
                <Checkbox
                  checked={this.state.mailerChecked}
                  onChange={this.handleChangeMailerChecked}
                  value="mailerChecked"
                  color="primary"
                />
              }
            />
            <div
              style={{
                fontSize: 12
              }}
            >
              You will receive no more than one email a year. PrestoSoft does not share or sell your
              personal information.
            </div>
          </Fragment>
        )}
        {this.state.error && (
          <div style={{ color: 'red', marginTop: 10, fontSize: 12 }}>{this.state.error}</div>
        )}
      </div>
    );
  }

  renderFormSwitcher() {
    return (
      <div style={{ position: 'absolute', top: 30, right: 24, fontSize: 12 }}>
        <span>
          {this.state.register ? 'Have an account?' : "Don't have an account?"}
          <span
            style={{ fontWeight: 'bold', cursor: 'pointer', marginLeft: 5 }}
            onClick={this.handleClickSwitchMode}
          >
            {this.state.register ? 'Login' : 'Register'}
          </span>
          {!this.state.register && (
            <span
              style={{ fontWeight: 'bold', cursor: 'pointer', marginLeft: 5 }}
              onClick={this.handleClickWhyRegister}
            >
              [Why?]
            </span>
          )}
        </span>
      </div>
    );
  }

  render() {
    const { open, classes } = this.props;

    return (
      <Fragment>
        <Dialog open={open} onClose={this.handleClose} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle>{this.state.register ? 'Register' : 'Login'}</DialogTitle>
          <DialogContent>
            {this.renderLoginForm()}
            {this.renderFormSwitcher()}
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={this.handleClose}>
              Cancel
            </Button>
            {!this.state.register && (
              <Button
                variant="outlined"
                disabled={!this.notEmpty([this.state.email])}
                onClick={this.handleClickForgotPassword}
              >
                Forgot Password?
              </Button>
            )}
            <Button
              color="primary"
              variant="raised"
              disabled={this.submitDisabled}
              onClick={this.handleSubmit}
            >
              {this.state.register ? 'Register' : 'Login'}
            </Button>
          </DialogActions>
        </Dialog>
        <WhyRegisterDialog
          open={this.state.isWhyRegisterDialogOpen}
          closeDialog={this.closeWhyRegisterDialog}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(LoginDialog);
