import React from 'react';
import { bool, string } from 'prop-types';

class AdContainer extends React.Component {
  componentDidMount() {
    if (window) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  render() {
    return (
      <div
        style={{
          position: 'relative',
          display: this.props.hideAdContainer ? 'none' : 'flex',
          marginTop: 20,
          marginBottom: 20,
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #888888',
          borderRadius: 4,
          height: 100,
          overflow: 'hidden'
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 1,
            left: 3,
            fontSize: 8,
            fontWeight: 600,
            color: '#555555'
          }}
        >
          ADVERTISEMENT
        </div>
        <ins
          className="adsbygoogle"
          style={{
            display: 'inline-block',
            width: '100%',
            height: '100px',
            justifyContent: 'center'
          }}
          data-ad-client="ca-pub-4700667168875048"
          data-ad-slot={this.props.adSlotId}
        />
      </div>
    );
  }
}

AdContainer.propTypes = {
  adSlotId: string,
  hideAdContainer: bool
};

export default AdContainer;
