import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  dialogPaper: {
    maxHeight: 500,
    width: 500
  }
};

class AboutDialog extends Component {
  static propTypes = {
    open: bool,
    closeDialog: func,
    configuration: object,
    openWhyPremiumDialog: func,
    classes: object
  };

  render() {
    const { open, closeDialog, configuration, openWhyPremiumDialog, classes } = this.props;

    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>{`DiffNow: Online Comparison Tool (v${configuration.version})`}</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 15 }}>
            DiffNow lets you compare text files, documents, binary files, and archives up to 2048 KB
            (8192 KB for{' '}
            <a href="#why-premium" onClick={openWhyPremiumDialog}>
              premium users
            </a>
            ) in size. You can either upload the files you wish to compare or enter their URLs.
            Results are returned as an interactive HTML report.
          </div>
          <div style={{ marginBottom: 15 }}>
            DiffNow is powered by{' '}
            <a href="http://www.prestosoft.com/edp_examdiffpro.asp?ref=diffnow-about">
              ExamDiff Pro
            </a>{' '}
            and uses all of the comparison plug-ins in the ExamDiff Pro{' '}
            <a href="http://www.prestosoft.com/edp_plugins.asp">plug-in library</a>.
          </div>
          <div style={{ marginBottom: 15 }}>
            All uploaded content and generated diff reports are securely encrypted using the DES-X
            symmetric-key encryption algorithm. All uploaded content is deleted immediately after
            comparison.
          </div>
          <div>
            If you are a premium user, you can always cancel subscription via the DiffNow user menu.
            You need to log into your account, click on your user name in the top right corner, then
            select Manage Premium Subscription, and from there click on Cancel Premium.
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={closeDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AboutDialog);
