import axios from 'axios';
import noop from 'lodash/noop';

import { DIFFNOW_API_URL } from './constants';

const USERS_URL = `${DIFFNOW_API_URL}/user`;

export async function checkIfLoggedIn(sessionToken) {
  return axios.get(`${USERS_URL}/checkIfLoggedIn?sessionToken=${sessionToken}`).catch(err => {
    noop();
  });
}

export function login(email, password, onSuccess, onError) {
  axios
    .post(`${USERS_URL}/login`, {
      emailAddress: email,
      password: password
    })
    .then(response => onSuccess(response.data.user, response.data.sessionToken))
    .catch(err => {
      if (err.response) {
        onError(`Error: ${err.response.data.message}`);
      } else {
        onError(`Error: ${err.message}`);
      }
    });
}

export function register(email, password, mailingList, onSuccess, onError) {
  axios
    .post(`${USERS_URL}/register`, {
      emailAddress: email,
      password: password,
      mailingList: mailingList.toString()
    })
    .then(response => {
      onSuccess(email);
    })
    .catch(err => {
      if (err.response) {
        onError(`Error: ${err.response.data.error}`);
      } else {
        onError(`Error: ${err.message}`);
      }
    });
}

export function logout(sessionToken, onSuccess) {
  axios
    .post(`${USERS_URL}/logout`, {
      sessionToken: sessionToken
    })
    .then(response => {
      onSuccess();
    })
    .catch(err => {
      noop();
    });
}

export function changePassword(sessionToken, passwordOld, passwordNew, onSuccess, onError) {
  axios
    .post(`${USERS_URL}/changePassword`, { sessionToken, passwordOld, passwordNew })
    .then(response => {
      onSuccess();
    })
    .catch(err => {
      if (err.response) {
        onError(`Error: ${err.response.data.error}`);
      } else {
        onError(`Error: ${err.message}`);
      }
    });
}

export function resetPassword(emailAddress, onSuccess, onError) {
  axios
    .post(`${USERS_URL}/resetPassword`, { emailAddress })
    .then(response => {
      onSuccess();
    })
    .catch(err => {
      if (err.response) {
        onError(`Error: ${err.response.data.error}`);
      } else {
        onError(`Error: ${err.message}`);
      }
    });
}

export function changePasswordForReset(
  emailAddress,
  confirmationKey,
  passwordNew,
  onSuccess,
  onError
) {
  axios
    .post(`${USERS_URL}/changePasswordForReset`, { emailAddress, confirmationKey, passwordNew })
    .then(response => {
      onSuccess();
    })
    .catch(err => {
      if (err.response) {
        onError(`Error: ${err.response.data.error}`);
      } else {
        onError(`Error: ${err.message}`);
      }
    });
}
