import includes from 'lodash/includes';

export const truncatedPrefix =
  'Note: Due to the large size of the comparison report, only the first ';
export const truncatedSuffixPremium =
  ' lines are being shown, based on your settings in Options | Results.';
export const truncatedSuffixNonPremium =
  ' lines are being shown. To see the full comparison results, please use <a href="http://www.prestosoft.com/edp_examdiffpro.asp?ref=diffnow-truncated">ExamDiff Pro</a> to compare these files. You can also adjust the limit if you are a <OpenWhyPremium source="truncated-message">premium user</OpenWhyPremium>.';
export const tips = [
  'DiffNow lets you compare ZIP and RAR archives, and displays the results as directories.',
  'You can use DiffNow to compare Word, Excel, PowerPoint, and PDF documents! DiffNow will automatically extract text from these documents.',
  "Don't like the default color scheme? You can fully customize every color in the comparison results from the <OpenOptions>Options panel</OpenOptions>. You can permanently store your color preferences by registering for an account.",
  'DiffNow can perform syntax highlighting for more than 20 popular programming and markup languages. You can also choose to ignore comments in the <OpenOptions>Options panel</OpenOptions>.',
  'By default, DiffNow ignores all whitespace (including blank lines). You can customize this in the <OpenOptions>Options panel</OpenOptions>.',
  'If you sign up for a DiffNow account, you can access all of your shared reports at any time.',
  'DiffNow uses <a href="http://blog.prestosoft.com/2008/07/fuzzy-matching.html">fuzzy matching</a> to intelligently align lines that are similar but not identical.',
  'DiffNow uses all of the comparison plug-ins in the <a href="http://www.prestosoft.com/edp_plugins.asp">ExamDiff Pro plug-in library</a>.',
  'DiffNow offers full Unicode support.',
  'Premium users of DiffNow can create multiple Options profiles.',
  'Premium users of DiffNow can compare files up to 8 MB in size.',
  'Ads do not show up for premium users of DiffNow',
  'Premium users of DiffNow can create diff reports of any size.',
  'Premium users of DiffNow can use regular expressions to ignore compared texts.'
];
export const tipsWindows = [
  'Like DiffNow but want to be able to compare directories too? Try <a href="http://www.prestosoft.com/edp_examdiffpro.asp?ref=diffnow-tip1">ExamDiff Pro</a>.',
  'DiffNow is powered by <a href="http://www.prestosoft.com/edp_examdiffpro.asp?ref=diffnow-tip2">ExamDiff Pro</a>. The downloadable version of ExamDiff Pro offers many more features, including three-way merge, directory comparison, and moved block detection.',
  '<a href="http://www.prestosoft.com/edp_examdiffpro.asp?ref=diffnow-tip3">ExamDiff Pro</a> can compare different kinds of inputs, such as local files against URLs, URLs against clipboard contents etc.'
];
export const tipsMacLinux = [
  'You can compare files via DiffNow from the command line using <a href="https://github.com/AlexNisnevich/diffnow-cli">diffnow-cli</a>.'
];

export const adBlockMessage =
  'DiffNow is largely supported by advertising income. Please whitelist diffnow.com in your Adblock settings or consider a <OpenWhyPremium source="adblock-msg">premium subscription</OpenWhyPremium> to get more functionality, get rid of ads, and support our service.';

export function getRandomTip() {
  let platformTips = tips;

  if (includes(navigator.platform, 'Win')) {
    platformTips = platformTips.concat(tipsWindows);
  } else if (includes(navigator.platform, 'Mac') || includes(navigator.platform, 'Linux')) {
    platformTips = platformTips.concat(tipsMacLinux);
  }

  return platformTips[Math.floor(Math.random() * platformTips.length)];
}
