import React, { Component, Fragment } from 'react';
import PropTypes, { bool, string, number, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';

import { optionsLabels } from '../../util/constants';

const styles = {
  checkBoxRoot: {
    width: 16,
    height: 16,
    margin: '8px 16px'
  }
};

class OptionsCheckboxTextfield extends Component {
  static propTypes = {
    checked: bool,
    checkboxOption: string,
    value: PropTypes.oneOfType([string, number]),
    option: string,
    setOption: func,
    disabled: bool,
    error: string,
    classes: object
  };

  render() {
    const {
      checked,
      checkboxOption,
      value,
      option,
      setOption,
      disabled,
      error,
      classes
    } = this.props;

    return (
      <Fragment>
        <FormControlLabel
          style={{ width: '50%' }}
          control={
            <Checkbox
              color="primary"
              disabled={disabled}
              checked={checked}
              className={classes.checkBoxRoot}
              onChange={event => {
                setOption(checkboxOption, event.target.checked);
              }}
            />
          }
          label={optionsLabels[option]}
        />
        <TextField
          error={!!error}
          helperText={error}
          id={option}
          value={value || ''}
          disabled={!checked || disabled}
          onChange={e => setOption(option, e.target.value)}
          style={{ width: '50%' }}
        />
        {disabled && <Icon style={{ marginLeft: 10 }}>star</Icon>}
      </Fragment>
    );
  }
}

export default withStyles(styles)(OptionsCheckboxTextfield);
