import React from 'react';
import { string, func, object, bool } from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const UserMenuButton = ({
  textColor,
  backgroundColor,
  label,
  action,
  icon,
  labelStyle,
  disabled,
  iconLeft,
  theme
}) => (
  <Button
    style={{
      color: textColor || theme.palette.topButton.color,
      backgroundColor: backgroundColor || theme.palette.topButton.backgroundColor
    }}
    disabled={disabled}
    onClick={action}
    variant="outlined"
  >
    {iconLeft && <Icon style={{ marginRight: label ? 10 : 0 }}>{icon}</Icon>}
    <div style={labelStyle || {}}>{label}</div>
    {!iconLeft && <Icon style={{ marginLeft: label ? 10 : 0 }}>{icon}</Icon>}
  </Button>
);

UserMenuButton.propTypes = {
  textColor: string,
  backgroundColor: string,
  label: string,
  action: func,
  icon: string,
  labelStyle: object,
  disabled: bool,
  iconLeft: bool,
  theme: object
};

export default withTheme()(UserMenuButton);
