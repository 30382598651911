import React, { Fragment } from 'react';
import PropTypes, { string, number, func, bool, object } from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import ReactTooltip from 'react-tooltip';
import isNil from 'lodash/isNil';

import { optionsLabels, optionsTooltips } from '../../util/constants';

const OptionsTextfield = ({ value, option, setOption, disabled, error, theme }) => (
  <Fragment>
    <div
      style={{
        width: 'calc(50% - 35px)',
        marginLeft: 35,
        color: theme.palette.primary.optionsText,
        textDecoration: optionsTooltips[option] ? 'underline' : 'none',
        cursor: disabled ? 'not-allowed' : 'auto',
        fontSize: '0.875rem'
      }}
    >
      <span data-tip={optionsTooltips[option]} data-for={`${option}-tooltip`}>
        {optionsLabels[option]}
      </span>
    </div>
    {optionsTooltips[option] && <ReactTooltip id={`${option}-tooltip`} multiline />}
    <TextField
      error={!!error}
      helperText={error}
      disabled={disabled}
      id={option}
      value={isNil(value) ? '' : value}
      onChange={e => setOption(option, e.target.value)}
      style={{ width: '50%' }}
    />
    {disabled && <Icon style={{ marginLeft: 10 }}>star</Icon>}
  </Fragment>
);

OptionsTextfield.propTypes = {
  value: PropTypes.oneOfType([string, number]),
  option: string,
  setOption: func,
  disabled: bool,
  error: string,
  theme: object
};

export default withTheme()(OptionsTextfield);
