import React, { Component, Fragment } from 'react';
import { bool, object, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import ReactTooltip from 'react-tooltip';

import edpLogoSmall from '../../edp-small.png';
import { getFeatures } from '../../util/constants';
import { examDiffProUrl, isPremium } from '../../util/common';
import FeatureComparisonPane from '../FeatureComparisonPane';

const styles = {
  dialogPaper: {
    width: 800,
    maxWidth: 800,
    overflow: 'visible'
  },
  dialogContent: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  edpFooterContainer: {
    position: 'absolute',
    bottom: 15
  },
  edpFooter: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonWithMargin: {
    marginLeft: 10
  }
};

class WhyPremiumDialog extends Component {
  static propTypes = {
    open: bool,
    user: object,
    closeDialog: func,
    openCheckoutDialog: func,
    classes: object,
    configuration: object
  };

  render() {
    const { open, user, closeDialog, classes, openCheckoutDialog, configuration } = this.props;
    const examDiffUrl = examDiffProUrl('diffnow-why-premium');
    const features = getFeatures(configuration);

    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Why Premium?</DialogTitle>
        <DialogContent>
          <div className={classes.dialogContent}>
            <FeatureComparisonPane title="DiffNow Standard" features={features.standardFeatures} />
            <FeatureComparisonPane
              title="DiffNow Premium"
              premium
              features={features.premiumFeatures}
            />
          </div>
          <div className={classes.edpFooterContainer}>
            <div className={classes.edpFooter}>
              <span>
                Want infinitely more features? Try{' '}
                <a href={examDiffUrl} target="_blank" rel="noopener noreferrer">
                  ExamDiff Pro
                </a>
              </span>
              <img src={edpLogoSmall} alt="ExamDiff Pro" style={{ marginLeft: 8 }} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button key="cancel" variant="outlined" onClick={closeDialog}>
            Cancel
          </Button>
          <Fragment>
            {!user && <ReactTooltip id="not-logged-in-tooltip-checkout" />}
            <div
              data-tip="You must be logged in to purchase a Premium subscription"
              data-for="not-logged-in-tooltip-checkout"
            >
              <Button
                color="primary"
                variant="raised"
                className={classes.buttonWithMargin}
                disabled={!user || isPremium(user)}
                onClick={openCheckoutDialog}
              >
                {isPremium(user) ? 'Already Subscribed' : 'Buy Subscription'}
                <Icon className={classes.buttonWithMargin}>shopping_cart</Icon>
              </Button>
            </div>
          </Fragment>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(WhyPremiumDialog);
