import React from 'react';
import { bool, func } from 'prop-types';
import Button from '@material-ui/core/Button';

import edpLogoLarge from '../../edp-small.png';

const CompareButton = ({ disabled, onClick }) => (
  <Button
    id="compare-button"
    color="primary"
    variant="raised"
    disabled={disabled}
    onClick={onClick}
    style={{ height: 48, fontSize: 18, position: 'absolute', bottom: 0 }}
  >
    <img
      alt="ExamDiff Pro"
      src={edpLogoLarge}
      style={{
        marginRight: 10,
        filter: disabled ? 'grayscale(100%)' : 'none'
      }}
    />
    Compare
  </Button>
);

CompareButton.propTypes = {
  disabled: bool,
  onClick: func
};

export default CompareButton;
