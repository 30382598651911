import axios from 'axios';

import { DIFFNOW_API_URL } from './constants';

const MAIL_URL = `${DIFFNOW_API_URL}/mail`;

export function sendMail(name, fromName, replyTo, subject, message, findOut, onSuccess, onError) {
  axios
    .post(`${MAIL_URL}/sendMail`, {
      name: name,
      fromName: fromName,
      replyTo: replyTo,
      subject: subject,
      message: message,
      findOut: findOut
    })
    .then(response => {
      onSuccess(response.data.result);
    })
    .catch(err => {
      if (err.response) {
        onError(err.response.data.result);
      } else {
        onError(err.message);
      }
    });
}
