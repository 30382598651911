import React, { Component } from 'react';
import { bool, func } from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { changePassword } from '../../util/user';

export default class ChangePasswordDialog extends Component {
  state = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    error: null
  };

  static propTypes = {
    open: bool,
    closeDialog: func
  };

  resetState = () => {
    this.setState({
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      error: null
    });
  };

  handleChange = name => event => {
    this.setState(
      {
        [name]: event.target.value
      },
      this.validateNewPassword
    );
  };

  get isPasswordTooShort() {
    return this.state.newPassword.length < 6;
  }

  get submitDisabled() {
    return (
      this.isPasswordTooShort ||
      this.state.newPassword !== this.state.newPasswordConfirm ||
      !this.state.oldPassword
    );
  }

  validateNewPassword = () => {
    if (this.state.newPassword !== this.state.newPasswordConfirm) {
      this.setState({ error: 'Your passwords must match!' });
    } else {
      this.setState({ error: null });
    }
  };

  handleChangePassword = () => {
    const { oldPassword, newPassword } = this.state;
    const sessionToken = localStorage.getItem('diffNowSessionToken');

    changePassword(
      sessionToken,
      oldPassword,
      newPassword,
      () => {
        this.props.closeDialog();
        this.resetState();
      },
      error => {
        this.setState({ error: error });
      }
    );
  };

  render() {
    const { open, closeDialog } = this.props;

    return (
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            type="password"
            id="oldPassword"
            label="Old password"
            value={this.state.oldPassword}
            onChange={this.handleChange('oldPassword')}
            margin="dense"
            fullWidth
          />
          <TextField
            error={this.isPasswordTooShort}
            helperText={
              this.isPasswordTooShort ? 'Your password must be at least 6 characters long.' : ''
            }
            type="password"
            id="newPassword"
            label="New password"
            value={this.state.newPassword}
            onChange={this.handleChange('newPassword')}
            margin="dense"
            fullWidth
          />
          <TextField
            type="password"
            id="newPasswordConfirm"
            label="Confirm new password"
            value={this.state.newPasswordConfirm}
            onChange={this.handleChange('newPasswordConfirm')}
            margin="dense"
            fullWidth
          />
          {this.state.error && (
            <div style={{ color: 'red', marginTop: 10, fontSize: 12 }}>{this.state.error}</div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.resetState();
              closeDialog();
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={this.handleChangePassword}
            disabled={this.submitDisabled}
          >
            Change Password
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
