export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const SUBSCRIBE_USER = 'SUBSCRIBE_USER';
export const RE_RENDER = 'RE_RENDER';
export const SET_STATUS = 'SET_STATUS';
export const SET_REPORT = 'SET_REPORT';
export const SET_EXAMDIFF_URI = 'SET_EXAMDIFF_URI';
export const SET_CLIP_TITLES = 'SET_CLIP_TITLES';
export const SET_FILE_TITLES = 'SET_FILE_TITLES';
export const SET_URL_TITLES = 'SET_URL_TITLES';
export const SET_CLIPS = 'SET_CLIPS';
export const SET_DOC_TYPE = 'SET_DOC_TYPE';
export const SET_CLIENT_ID = 'SET_CLIENT_ID';
export const SET_OPTIONS_PROFILES = 'SET_OPTIONS_PROFILES';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_NEW_OPTION = 'SET_NEW_OPTION';
export const SAVE_NEW_OPTIONS = 'SAVE_NEW_OPTIONS';
export const RESET_NEW_OPTIONS = 'RESET_NEW_OPTIONS';
export const SET_DEFAULT_OPTIONS = 'SET_DEFAULT_OPTIONS';
export const SET_TRUNCATED_STATUS = 'SET_TRUNCATED_STATUS';
export const SET_COMPARISON_COUNT = 'SET_COMPARISON_COUNT';
export const SET_CONFIGURATION = 'SET_CONFIGURATION';
export const SET_URLS = 'SET_URLS';
export const SET_FIRST_FILE = 'SET_FIRST_FILE';
export const SET_SECOND_FILE = 'SET_SECOND_FILE';
export const SET_LAST_COMPARED_TAB_INDEX = 'SET_LAST_COMPARED_TAB_INDEX';
export const SET_COLLAPSED_TAB_INDEX = 'SET_COLLAPSED_TAB_INDEX';
export const SET_THEME = 'SET_THEME';
export const SET_PREMIUM_SOURCE = 'SET_PREMIUM_SOURCE';
export const SET_REPORT_READY = 'SET_REPORT_READY';

export function loggedIn(user) {
  return {
    type: LOGGED_IN,
    payload: { user }
  };
}

export function loggedOut() {
  return {
    type: LOGGED_OUT
  };
}

export function subscribeUser() {
  return {
    type: SUBSCRIBE_USER
  };
}

export function rerender() {
  return {
    type: RE_RENDER
  };
}

export function setStatus(status) {
  return {
    type: SET_STATUS,
    payload: { status }
  };
}

export function setReport(reportId, durationKey) {
  return {
    type: SET_REPORT,
    payload: { reportId, durationKey }
  };
}

export function setExamDiffUri(examDiffUri) {
  return {
    type: SET_EXAMDIFF_URI,
    payload: { examDiffUri }
  };
}

export function setClipTitles(firstClipTitle, secondClipTitle) {
  return {
    type: SET_CLIP_TITLES,
    payload: { firstClipTitle, secondClipTitle }
  };
}

export function setFileTitles(firstFileTitle, secondFileTitle) {
  return {
    type: SET_FILE_TITLES,
    payload: { firstFileTitle, secondFileTitle }
  };
}

export function setUrlTitles(firstUrlTitle, secondUrlTitle) {
  return {
    type: SET_URL_TITLES,
    payload: { firstUrlTitle, secondUrlTitle }
  };
}

export function setClips(firstClip, secondClip) {
  return {
    type: SET_CLIPS,
    payload: { firstClip, secondClip }
  };
}

export function setDocType(docType) {
  return {
    type: SET_DOC_TYPE,
    payload: { docType }
  };
}

export function setClientId(clientId) {
  return {
    type: SET_CLIENT_ID,
    payload: { clientId }
  };
}

export function setOptionsProfiles(optionsProfiles) {
  return {
    type: SET_OPTIONS_PROFILES,
    payload: { optionsProfiles }
  };
}

export function setOptions(options) {
  return {
    type: SET_OPTIONS,
    payload: { options }
  };
}

export function setNewOption(newOption) {
  return {
    type: SET_NEW_OPTION,
    payload: { newOption }
  };
}

export function saveNewOptions() {
  return {
    type: SAVE_NEW_OPTIONS
  };
}

export function resetNewOptions() {
  return {
    type: RESET_NEW_OPTIONS
  };
}

export function setDefaultOptions(defaultOptions) {
  return {
    type: SET_DEFAULT_OPTIONS,
    payload: { defaultOptions }
  };
}

export function setTruncatedStatus(truncatedStatus) {
  return {
    type: SET_TRUNCATED_STATUS,
    payload: { isTruncated: truncatedStatus }
  };
}

export function setComparisonCount(newComparisonCount, newComparisonStartDate) {
  return {
    type: SET_COMPARISON_COUNT,
    payload: { newComparisonCount, newComparisonStartDate }
  };
}

export function setConfiguration(configuration) {
  return {
    type: SET_CONFIGURATION,
    payload: { configuration }
  };
}

export function setUrls(firstUrl, secondUrl) {
  return {
    type: SET_URLS,
    payload: { firstUrl, secondUrl }
  };
}

export function setFirstFile(firstFileStatus, firstFileName, firstFileUploadedName) {
  return {
    type: SET_FIRST_FILE,
    payload: { firstFileStatus, firstFileName, firstFileUploadedName }
  };
}

export function setSecondFile(secondFileStatus, secondFileName, secondFileUploadedName) {
  return {
    type: SET_SECOND_FILE,
    payload: { secondFileStatus, secondFileName, secondFileUploadedName }
  };
}

export function setLastComparedTabIndex(lastComparedTabIndex) {
  return {
    type: SET_LAST_COMPARED_TAB_INDEX,
    payload: { lastComparedTabIndex }
  };
}

export function setCollapsedTabIndex(collapsedTabIndex) {
  return {
    type: SET_COLLAPSED_TAB_INDEX,
    payload: { collapsedTabIndex }
  };
}

export function setTheme(theme) {
  return {
    type: SET_THEME,
    payload: { theme }
  };
}

export function setPremiumSource(premiumSource) {
  return {
    type: SET_PREMIUM_SOURCE,
    payload: { premiumSource }
  };
}

export function setReportReady(isReportReady) {
  return {
    type: SET_REPORT_READY,
    payload: { isReportReady }
  };
}
