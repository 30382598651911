import axios from 'axios';
import noop from 'lodash/noop';
import pick from 'lodash/pick';

import { DIFFNOW_API_URL } from './constants';

const OPTIONS_URL = `${DIFFNOW_API_URL}/userOptions`;

export function getUniqueOptions(defaultOptions, userOptions) {
  const keys = Object.keys(userOptions).filter(key => userOptions[key] !== defaultOptions[key]);
  return pick(userOptions, keys);
}

export function getOptionsProfiles(sessionToken, onSuccess) {
  axios
    .get(`${OPTIONS_URL}/getOptionsProfiles?sessionToken=${sessionToken}`)
    .then(response => {
      onSuccess(response.data.optionsProfiles);
    })
    .catch(err => {
      noop();
    });
}

export function getCurrentUserOptions(sessionToken, onSuccess) {
  axios
    .get(`${OPTIONS_URL}/getCurrentUserOptions?sessionToken=${sessionToken}`)
    .then(response => {
      onSuccess(JSON.parse(response.data.optionsJson));
    })
    .catch(err => {
      onSuccess({});
    });
}

export function getDefaultOptions(sessionToken, onSuccess) {
  axios
    .get(`${OPTIONS_URL}/getDefaultOptions?sessionToken=${sessionToken}`)
    .then(response => {
      onSuccess(JSON.parse(response.data.optionsJson));
    })
    .catch(err => {
      onSuccess({});
    });
}

export function getUserOptionsByName(sessionToken, optionsName, onSuccess) {
  axios
    .get(
      `${OPTIONS_URL}/getUserOptionsByName?sessionToken=${sessionToken}&optionsName=${optionsName}`
    )
    .then(response => {
      onSuccess(JSON.parse(response.data.optionsJson));
    })
    .catch(err => {
      noop();
    });
}

export function setUserOptions(sessionToken, optionsJson, defaultOptions, onSuccess) {
  axios
    .post(`${OPTIONS_URL}/setUserOptions`, {
      sessionToken: sessionToken,
      optionsJson: getUniqueOptions(defaultOptions, optionsJson)
    })
    .then(response => {
      onSuccess(optionsJson);
    })
    .catch(err => {
      noop();
    });
}

export function deleteUserOptionsByName(sessionToken, optionsName, onSuccess) {
  axios
    .post(`${OPTIONS_URL}/deleteUserOptionsByName`, {
      sessionToken: sessionToken,
      optionsName: optionsName
    })
    .then(response => {
      onSuccess(optionsName);
    })
    .catch(err => {
      noop();
    });
}

export function setCurrentProfile(sessionToken, optionsName, onSuccess) {
  axios
    .post(`${OPTIONS_URL}/setCurrentProfile`, {
      sessionToken: sessionToken,
      optionsName: optionsName
    })
    .then(response => {
      onSuccess(optionsName);
    })
    .catch(err => {
      noop();
    });
}
