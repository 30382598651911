import { combineReducers } from 'redux';

import gl from './global';
import version from './version';

const rootReducer = combineReducers({
  global: gl,
  version: version
});

export default rootReducer;
