import React from 'react';
import { bool, func } from 'prop-types';
import Icon from '@material-ui/core/Icon';

const ConsentFooter = ({ isDismissed, openTosDialog, dismiss }) => (
  <div
    style={{
      width: '100%',
      display: isDismissed ? 'none' : 'flex',
      position: 'fixed',
      bottom: 0,
      height: 64,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#555',
      color: '#CCC',
      padding: '0 48px',
      boxSizing: 'border-box'
    }}
  >
    <div style={{ fontSize: 14 }}>
      <span>
        This site uses cookies to deliver our services and to allow for analysis of how people use
        our website in order to improve your experience and our services. By using our site, you
        acknowledge that you have read and understand our&nbsp;
      </span>
      <a
        href="#terms-of-service"
        style={{ color: 'white', textDecoration: 'underline', cursor: 'pointer' }}
        onClick={openTosDialog}
      >
        Terms of Service
      </a>
      .
    </div>
    <Icon style={{ marginLeft: 20, cursor: 'pointer', color: 'white' }} onClick={dismiss}>
      clear
    </Icon>
  </div>
);

ConsentFooter.propTypes = {
  isDismissed: bool,
  openTosDialog: func,
  dismiss: func
};

export default ConsentFooter;
