import React, { Component } from 'react';
import { connect } from 'react-redux';
import { func, number, object } from 'prop-types';
import { Link } from 'react-router-dom';
import { withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import { COMPARE_PANE_TRANSITION_MS } from '../util/constants';
import { examDiffProUrl } from '../util/common';
import * as actions from '../actions/global';

function mapStateToProps(state) {
  return {
    collapsedTabIndex: state.global.collapsedTabIndex
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onSetCollapsedTabIndex(newCollapsedTabIndex) {
      dispatch(actions.setCollapsedTabIndex(newCollapsedTabIndex));
    }
  };
}

class CompareFolders extends Component {
  static propTypes = {
    onSetCollapsedTabIndex: func,
    collapsedTabIndex: number,
    theme: object
  };

  constructor(props) {
    super(props);

    props.onSetCollapsedTabIndex(null);

    this.state = {
      open: false
    };
  }

  static getDerivedStateFromProps = (props, state) => ({
    ...state,
    open: props.collapsedTabIndex === null
  });

  componentDidMount() {
    this.props.onSetCollapsedTabIndex(null);
  }

  renderSampleButton(label, url1, url2) {
    const DEMO_URL = 'https://www.diffnow.com/demo/';

    return (
      <Button
        style={{ marginRight: 10, ...this.props.theme.palette.topButton }}
        component={Link}
        to={`/compare-urls?url1=${DEMO_URL}${url1}&url2=${DEMO_URL}${url2}&demo=1`}
      >
        {label}
      </Button>
    );
  }

  get styles() {
    return {
      outer: {
        padding: 20
      },
      contents: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: 216
      },
      buttonList: {
        display: 'flex'
      },
      heading: {
        fontSize: 24,
        marginBottom: 10,
        color: '#666'
      }
    };
  }

  render() {
    return (
      <div>
        <Paper style={this.state.open ? this.styles.outer : {}}>
          <Collapse
            in={this.state.open}
            timeout={COMPARE_PANE_TRANSITION_MS}
            style={this.state.open ? {} : { visibility: 'hidden' }}
          >
            <div style={this.styles.contents}>
              <Typography component={'span'}>
                <div style={this.styles.heading}>How can I compare folders?</div>
                <div style={{ marginTop: 15 }}>
                  DiffNow can only compare directories if they are
                  <a href="/compare-files" style={{ marginLeft: 2 }}>
                    uploaded as ZIP archives
                  </a>
                  .
                </div>
                <div style={{ marginTop: 10 }}>
                  If you would like to compare directories on your file system, use
                  <a
                    href={examDiffProUrl('diffnow-folder-comparison')}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: 2 }}
                  >
                    ExamDiff Pro
                  </a>
                  , our desktop software, instead.
                </div>
              </Typography>
            </div>
          </Collapse>
        </Paper>
      </div>
    );
  }
}

export default withTheme()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CompareFolders)
);
