import React, { Component } from 'react';
import { string, func, bool, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    zIndex: 1500
  },
  textField: {
    width: '100%'
  },
  content: {
    width: 300
  },
  error: {
    fontSize: 12,
    marginTop: 10,
    color: 'red'
  }
};

class RenameReportDialog extends Component {
  state = {
    newTitle: undefined,
    error: undefined
  };

  handleChange = event => {
    this.setState({
      newTitle: event.target.value
    });
  };

  handleClose = () => {
    this.setState({ newTitle: undefined, error: undefined });
    this.props.handleClose();
  };

  renameReport = (shortUrl, newReportTitle) => {
    this.props.renameReport(
      shortUrl,
      newReportTitle,
      () => {
        this.handleClose();
      },
      error => {
        this.setState({ error });
      }
    );
  };

  render() {
    const { shortUrl, reportTitle, open, classes } = this.props;

    return (
      <Dialog open={open} onClose={this.handleClose} className={classes.root}>
        <DialogTitle>Rename Report</DialogTitle>
        <DialogContent className={classes.content}>
          <TextField
            label="New Report Title"
            defaultValue={reportTitle}
            className={classes.textField}
            onChange={this.handleChange}
          />
          {this.state.error && <div className={classes.error}>{this.state.error}</div>}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            disabled={
              this.state.newTitle === '' ||
              (this.state.newTitle && this.state.newTitle.trim() === '')
            }
            color="primary"
            variant="outlined"
            onClick={() => {
              this.renameReport(shortUrl, this.state.newTitle || reportTitle);
            }}
          >
            Rename
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RenameReportDialog.propTypes = {
  shortUrl: string,
  reportTitle: string,
  renameReport: func,
  handleClose: func,
  open: bool,
  classes: object
};

export default withStyles(styles)(RenameReportDialog);
