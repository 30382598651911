import React, { Component, Fragment } from 'react';
import { string, element, func, bool, object } from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { withTheme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const styles = {
  label: {
    height: 24
  }
};

class TopButton extends Component {
  static propTypes = {
    label: string,
    icon: string,
    operation: func,
    noMarginRight: bool,
    tooltip: string,
    disabled: bool,
    mustBeLoggedIn: bool,
    theme: object,
    rightElement: element,
    hideIcon: bool,
    classes: object
  };

  render() {
    const {
      label,
      icon,
      operation,
      noMarginRight,
      tooltip,
      disabled,
      mustBeLoggedIn,
      theme,
      rightElement,
      hideIcon,
      classes
    } = this.props;

    return (
      <Fragment>
        {((mustBeLoggedIn && disabled) || tooltip) && (
          <ReactTooltip id={`top-button-tooltip-${icon}`} />
        )}
        <div
          data-tip={tooltip || 'You must be logged in to use this feature'}
          data-for={`top-button-tooltip-${icon}`}
        >
          <Button
            disabled={disabled}
            variant="contained"
            color="secondary"
            onClick={operation}
            style={{
              border: theme.palette.buttonBorder,
              marginRight: noMarginRight ? 2 : 10
            }}
            classes={{
              label: classes.label
            }}
          >
            {!hideIcon && <Icon style={{ marginRight: label ? 10 : 0 }}>{icon}</Icon>}
            {label}
            {rightElement ? rightElement : null}
          </Button>
        </div>
      </Fragment>
    );
  }
}

export default withTheme()(withStyles(styles)(TopButton));
