import axios from 'axios';

import { DIFFNOW_API_URL } from './constants';

const USERS_URL = `${DIFFNOW_API_URL}/subscription`;

export function addNewSubscription(
  sessionToken,
  subscriptionToken,
  plan,
  source,
  onSuccess,
  onError
) {
  axios
    .post(`${USERS_URL}/addNewSubscription`, {
      sessionToken,
      subscriptionToken,
      plan,
      source
    })
    .then(response => onSuccess())
    .catch(err => {
      if (err.response) {
        onError({ error: `Error: ${err.response.data}` });
      } else {
        onError({ error: `Error: ${err.message}` });
      }
    });
}

export async function getPremiumStatus(sessionToken) {
  try {
    const response = await axios.get(`${USERS_URL}/getPremiumStatus?sessionToken=${sessionToken}`);
    return response.data;
  } catch (err) {
    if (err.response) {
      throw err.response.data.error;
    } else {
      throw err.message;
    }
  }
}

export function cancelSubscription(sessionToken, onSuccess, onError) {
  axios
    .post(`${USERS_URL}/cancelSubscription`, {
      sessionToken
    })
    .then(response => onSuccess(response.data))
    .catch(err => {
      if (err.response) {
        onError(err.response.data.error);
      } else {
        onError(err.message);
      }
    });
}

export function reactivateSubscription(sessionToken, onSuccess, onError) {
  axios
    .post(`${USERS_URL}/reactivateSubscription`, {
      sessionToken
    })
    .then(response => onSuccess(response.data))
    .catch(err => {
      if (err.response) {
        onError(err.response.data.error);
      } else {
        onError(err.message);
      }
    });
}

export function updatePaymentMethod(sessionToken, tokenId, onSuccess, onError) {
  axios
    .post(`${USERS_URL}/updateCard`, {
      sessionToken,
      tokenId
    })
    .then(response => onSuccess(response.data))
    .catch(err => {
      if (err.response) {
        onError(err.response.data.error);
      } else {
        onError(err.message);
      }
    });
}
