import { isPremium, comparisonLimit } from './common';

export function setUpComparisonCounter(onSetComparisonCount, configuration) {
  let comparisonCount = 0;
  let comparisonStartDate = undefined;

  try {
    comparisonCount = JSON.parse(window.localStorage.getItem('diffNowComparisonCount'));
    comparisonStartDate = new Date(window.localStorage.getItem('diffNowComparisonStartDate'));
  } catch (e) {
    // Fall back to default values
  }

  if (comparisonCount && comparisonStartDate) {
    if (isComparisonCountExpired(comparisonStartDate, configuration)) {
      resetComparisonCounter(onSetComparisonCount);
    } else {
      onSetComparisonCount(comparisonCount, comparisonStartDate);
    }
  } else {
    resetComparisonCounter(onSetComparisonCount);
  }
}

export function resetComparisonCounter(onSetComparisonCount) {
  setComparisonCount(0, null, onSetComparisonCount);
}

export function bumpComparisonCounter({
  comparisonCount,
  comparisonStartDate,
  onSetComparisonCount,
  configuration,
  user
}) {
  if (!isPremium(user)) {
    if (comparisonCount && comparisonStartDate) {
      if (isComparisonCountExpired(comparisonStartDate, configuration)) {
        setComparisonCount(1, new Date(), onSetComparisonCount);
      } else {
        setComparisonCount(comparisonCount + 1, comparisonStartDate, onSetComparisonCount);
      }
    } else {
      setComparisonCount(1, new Date(), onSetComparisonCount);
    }
  }
}

export function setComparisonCount(
  newComparisonCount,
  newComparisonStartDate,
  onSetComparisonCount
) {
  onSetComparisonCount(newComparisonCount, newComparisonStartDate);

  try {
    window.localStorage.setItem('diffNowComparisonCount', newComparisonCount);
    window.localStorage.setItem('diffNowComparisonStartDate', newComparisonStartDate);
  } catch (e) {
    // Fall back to default values
  }
}

export function isComparisonCountExpired(comparisonStartDate, configuration) {
  const { useMonthlyComparisonLimits } = configuration;
  const MS_IN_A_DAY = 86400000;
  const MS_IN_A_MONTH = 30 * MS_IN_A_DAY;

  const expirationLimit = useMonthlyComparisonLimits ? MS_IN_A_MONTH : MS_IN_A_DAY;

  const currentTime = new Date();
  return currentTime - comparisonStartDate > expirationLimit;
}

export function comparisonsUsedStatus(comparisonCount, user, configuration) {
  const {
    comparisonCountLimitNotLoggedInWarning,
    comparisonCountLimitNotLoggedInMax,
    monthlyComparisonCountLimitWarning,
    monthlyComparisonCountLimitMax,
    useMonthlyComparisonLimits
  } = configuration;

  const warningLimit = useMonthlyComparisonLimits
    ? monthlyComparisonCountLimitWarning
    : comparisonCountLimitNotLoggedInWarning;
  const maxLimit = useMonthlyComparisonLimits
    ? monthlyComparisonCountLimitMax
    : comparisonCountLimitNotLoggedInMax;
  const limitTypeDescriptor = useMonthlyComparisonLimits ? 'monthly' : 'daily';
  const userNotLoggedInOrNotPremium = !user || !isPremium(user);

  if (userNotLoggedInOrNotPremium && comparisonCount >= warningLimit) {
    return {
      message: `You have used ${comparisonCount} of your ${maxLimit} ${limitTypeDescriptor} comparisons. <OpenWhyPremium source="comparison-count">Premium users</OpenWhyPremium> can make an unlimited number of comparisons.`,
      type: 'info'
    };
  } else {
    return { message: null, type: null };
  }
}

export function comparisonLimitReachedStatus(user, configuration) {
  const secondaryAdditionalMessage = configuration.useMonthlyComparisonLimits
    ? 'Sign up for <OpenWhyPremium source="comparison-count">DiffNow Premium</OpenWhyPremium> to unlock unlimited comparisons.'
    : 'Register for free to increase your comparison limit to 15 or sign up for <OpenWhyPremium source="comparison-count">DiffNow Premium</OpenWhyPremium> to unlock unlimited comparisons.';
  const additionalMessage = user
    ? 'Sign up for <OpenWhyPremium source="comparison-count">DiffNow Premium</OpenWhyPremium> to unlock unlimited comparisons.'
    : secondaryAdditionalMessage;
  const limitTypeDescriptor = configuration.useMonthlyComparisonLimits ? 'monthly' : 'daily';

  return {
    message: `You have hit your ${limitTypeDescriptor} comparison limit of ${comparisonLimit(
      user,
      configuration
    )}. ${additionalMessage}`,
    type: 'error'
  };
}
