import React, { Component } from 'react';
import { bool, string, number, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Icon from '@material-ui/core/Icon';
import ReactTooltip from 'react-tooltip';

import { optionsLabels, optionsTooltips } from '../../util/constants';

const styles = {
  checkBoxRoot: {
    width: 16,
    height: 16,
    margin: '8px 16px'
  }
};

class OptionsCheckbox extends Component {
  static propTypes = {
    checked: bool,
    option: string,
    columns: number,
    setOption: func,
    disabled: bool,
    classes: object
  };

  render() {
    const { checked, option, columns, setOption, disabled, classes } = this.props;
    const Label = optionsTooltips[option] === undefined ? 'span' : 'u';

    return (
      <div style={{ width: `${columns * 25}%`, display: 'flex' }}>
        {optionsTooltips[option] && <ReactTooltip id={`${option}-tooltip`} multiline />}
        <FormControlLabel
          classes={{ root: classes.formControlLabelRoot }}
          control={
            <Checkbox
              color="primary"
              disabled={disabled}
              checked={checked}
              className={classes.checkBoxRoot}
              onChange={event => {
                setOption(option, event.target.checked);
              }}
            />
          }
          label={
            <Label data-tip={optionsTooltips[option]} data-for={`${option}-tooltip`}>
              {optionsLabels[option]}
            </Label>
          }
        />
        {disabled && <Icon>star</Icon>}
      </div>
    );
  }
}

export default withStyles(styles)(OptionsCheckbox);
