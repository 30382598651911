import React, { Component } from 'react';
import { func, string, object, number } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import isEmpty from 'lodash/isEmpty';
import { Amplitude } from '@amplitude/react-amplitude';

import { COMPARE_CLIPS, COMPARE_PANE_TRANSITION_MS } from '../util/constants';
import TextInputPane from '../components/comparison/TextInputPane';
import CompareButton from '../components/comparison/CompareButton';
import DocTypeSelector from '../components/comparison/DocTypeSelector';

function mapStateToProps(state) {
  return {
    theme: state.global.theme,
    user: state.global.user,
    clientId: state.global.clientId,
    options: state.global.options,
    comparisonCount: state.global.comparisonCount,
    comparisonStartDate: state.global.comparisonStartDate,
    configuration: state.global.configuration,
    firstTitle: state.global.firstClipTitle,
    secondTitle: state.global.secondClipTitle,
    firstClip: state.global.firstClip,
    secondClip: state.global.secondClip,
    docType: state.global.docType,
    collapsedTabIndex: state.global.collapsedTabIndex
  };
}

export class CompareClips extends Component {
  static propTypes = {
    theme: string,
    onCompare: func,
    onSetCollapsedTabIndex: func,
    clientId: string,
    options: object,
    user: object,
    firstTitle: string,
    secondTitle: string,
    firstClip: string,
    secondClip: string,
    docType: string,
    collapsedTabIndex: number,
    openWhyPremiumDialog: func
  };

  constructor(props) {
    super(props);

    const { firstTitle, firstClip, secondTitle, secondClip, docType } = this.props;

    this.state = { open: false, firstTitle, firstClip, secondTitle, secondClip, docType };
  }

  static getDerivedStateFromProps = (props, state) => ({
    ...state,
    open: props.collapsedTabIndex === null
  });

  componentDidMount() {
    this.props.onSetCollapsedTabIndex(null);
  }

  compareClips = async () => {
    this.props.onCompare(COMPARE_CLIPS, this.getComparisonPayload());
  };

  getComparisonPayload = () => {
    const { user, clientId, options, theme } = this.props;
    const { firstTitle, secondTitle, firstClip, secondClip, docType } = this.state;

    const sessionToken = user ? user.sessionToken : null;

    return {
      title1: firstTitle,
      title2: secondTitle,
      clip1: firstClip,
      clip2: secondClip,
      sessionId: clientId,
      sessionToken,
      optionsJson: options,
      clipType: docType,
      darkMode: theme === 'dark'
    };
  };

  selectDocType = docType => {
    this.setState({ docType });
  };

  isValidCompareState = () => !isEmpty(this.state.firstClip) && !isEmpty(this.state.secondClip);

  render() {
    const { openWhyPremiumDialog } = this.props;

    return (
      <div>
        <Paper
          style={{
            padding: this.state.open ? 20 : 0,
            paddingBottom: this.state.open ? 12 : 0
          }}
        >
          <Collapse
            in={this.state.open}
            timeout={COMPARE_PANE_TRANSITION_MS}
            style={this.state.open ? {} : { visibility: 'hidden' }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <TextInputPane
                left
                title={this.state.firstTitle}
                clip={this.state.firstClip}
                onChangeTitle={newTitle => this.setState({ firstTitle: newTitle })}
                onChangeClip={newClip => this.setState({ firstClip: newClip })}
              />
              <TextInputPane
                title={this.state.secondTitle}
                clip={this.state.secondClip}
                onChangeTitle={newTitle => this.setState({ secondTitle: newTitle })}
                onChangeClip={newClip => this.setState({ secondClip: newClip })}
              />
            </div>

            <div
              style={{
                position: 'relative',
                display: 'flex',
                marginTop: 15,
                height: 75,
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  left: 0,
                  fontSize: 12,
                  color: '#999999'
                }}
              >
                First 2048 KB will be compared (8192 KB for{' '}
                <a href="#why-premium" onClick={openWhyPremiumDialog}>
                  premium users
                </a>
                )
              </div>

              <Amplitude>
                {({ logEvent }) => (
                  <CompareButton
                    disabled={!this.isValidCompareState()}
                    onClick={() => {
                      this.compareClips();
                      logEvent('START_COMPARISON', { type: 'CLIPS' });
                    }}
                  />
                )}
              </Amplitude>

              <DocTypeSelector docType={this.state.docType} onSelect={this.selectDocType} />
            </div>
          </Collapse>
        </Paper>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(CompareClips));
