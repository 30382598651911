import React from 'react';
import { array, string } from 'prop-types';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { lightTheme, darkTheme } from '../themes/personal';

function mapStateToProps(state) {
  return {
    theme: state.global.theme
  };
}

class ConnectedThemeProvider extends React.Component {
  static propTypes = {
    theme: string,
    children: array
  };

  render() {
    const { children, theme } = this.props;
    let muiTheme = null;

    if (theme === 'light') {
      muiTheme = lightTheme;
    } else if (theme === 'dark') {
      muiTheme = darkTheme;
    }

    return <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>;
  }
}

export default connect(mapStateToProps)(ConnectedThemeProvider);
