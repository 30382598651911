import * as React from 'react';
import { arrayOf, element, func, oneOfType } from 'prop-types';

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: oneOfType([element, arrayOf(element)]),
    openContactDialog: func
  };

  state = {
    error: null
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return (
        <div style={{ marginLeft: 35 }}>
          <p style={{ fontSize: '2.5em', marginBottom: 5 }}>Oops!</p>
          <p>
            Something went wrong on our server. We{"'"}re working to resolve the issue, and you can
            help by{' '}
            <span
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={this.props.openContactDialog}
            >
              submitting an error report
            </span>
            .
          </p>
        </div>
      );
    }
    return this.props.children;
  }
}
