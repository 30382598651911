import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  dialogPaper: {
    maxWidth: 500,
    width: 500
  }
};

class WhyRegisterDialog extends Component {
  static propTypes = {
    open: bool,
    closeDialog: func,
    classes: object
  };

  render() {
    const { open, closeDialog, classes } = this.props;

    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Why Register?</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 15 }}>
            If you use DiffNow regularly, registering for an account can be useful for several
            reasons:
          </div>
          <div>
            <ul>
              <li>
                You can save and share diff reports, as well as being able to manage your saved diff
                reports.
              </li>
              <li>
                Your options are tied to your account, so you only have to change them once rather
                than every time you visit DiffNow.
              </li>
            </ul>
          </div>
          <div>
            Registration only takes a minute and is of course completely free. We are also planning
            on adding more features to user accounts in the future, such as more advanced comparison
            options. Be sure to let us know if you have any feature suggestions using the Feedback
            link on the left side.
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={closeDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(WhyRegisterDialog);
