import React, { Component, Fragment } from 'react';
import { string, func, bool } from 'prop-types';
import Dropzone from 'react-dropzone';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from '@material-ui/core/Icon';

export default class FileInput extends Component {
  state = {
    dropzoneActive: false
  };

  static propTypes = {
    label: string,
    onUpload: func,
    fileName: string,
    status: string,
    disabled: bool
  };

  render() {
    const { label, onUpload, fileName, status, disabled } = this.props;
    const { dropzoneActive } = this.state;

    let statusComponent = null;

    if (status === 'uploading') {
      statusComponent = <CircularProgress size={32} />;
    } else if (status === 'uploaded') {
      statusComponent = <Icon style={{ color: 'green' }}>check</Icon>;
    } else if (status === 'error') {
      statusComponent = <Icon style={{ color: 'red' }}>error</Icon>;
    }

    return (
      <Dropzone
        disableClick
        onDragEnter={() => this.setState({ dropzoneActive: true })}
        onDragLeave={() => this.setState({ dropzoneActive: false })}
        style={{ display: 'flex', alignItems: 'center', height: 50 }}
        onDrop={files => {
          onUpload(files[0]);
          this.setState({ dropzoneActive: false });
        }}
      >
        <input
          id={label
            .toLowerCase()
            .split(' ')
            .join('-')}
          disabled={disabled}
          onChange={e => onUpload(e.target.files[0])}
          onClick={e => (e.target.value = null)}
          style={{ display: 'none' }}
          type="file"
        />
        <label
          htmlFor={label
            .toLowerCase()
            .split(' ')
            .join('-')}
        >
          <Button
            disabled={disabled}
            variant="raised"
            color="primary"
            style={{ width: 200, marginRight: 20 }}
            component="span"
          >
            {label}
          </Button>
        </label>
        <div
          style={{
            width: 'calc(100% - 200px)',
            height: 48,
            border: dropzoneActive ? '2px dotted #DDDDDD' : 'none',
            borderRadius: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: dropzoneActive ? 'center' : 'flex-start'
          }}
        >
          {dropzoneActive ? (
            <div
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: '#DDDDDD'
              }}
            >
              DROP FILE HERE
            </div>
          ) : (
            <Fragment>
              <div style={{ marginRight: 20 }}>{statusComponent}</div>
              <div>{fileName}</div>
            </Fragment>
          )}
        </div>
      </Dropzone>
    );
  }
}
