import React from 'react';
import Typography from '@material-ui/core/Typography';

import comparingLoader from '../../comparing-loader.gif';

const ComparisonAnimation = () => (
  <Typography style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
    <div>
      <img src={comparingLoader} alt="" style={{ width: 600, margin: '40px 0 20px' }} />
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between', width: 600, fontSize: 20 }}>
      <div style={{ marginLeft: 20 }}>Files</div>
      <div style={{ marginLeft: 30 }}>ExamDiff Pro</div>
      <div style={{ marginRight: 9 }}>DiffNow</div>
    </div>
  </Typography>
);

export default ComparisonAnimation;
