import React from 'react';
import { func, bool, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    zIndex: 1500
  },
  cancelButton: {
    color: '#ff1744'
  }
};

const ConfirmCancelSubscriptionDialog = ({ cancelSubscription, handleClose, open, classes }) => (
  <Dialog open={open} onClose={handleClose} className={classes.root}>
    <DialogTitle>Confirm Cancel Subscription</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Are you sure you want to cancel your DiffNow Premium subscription? You can always reactivate
        your premium subscription in the <b>Manage Premium Subscription</b> dialog up until the
        expiration of your subscription.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        classes={{ text: classes.cancelButton }}
        onClick={() => {
          cancelSubscription();
          handleClose();
        }}
      >
        Cancel Subscription
      </Button>
      <Button color="primary" variant="outlined" onClick={handleClose}>
        Go Back
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmCancelSubscriptionDialog.propTypes = {
  cancelSubscription: func,
  handleClose: func,
  open: bool,
  classes: object
};

export default withStyles(styles)(ConfirmCancelSubscriptionDialog);
