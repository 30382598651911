import React, { Component } from 'react';
import { bool, string, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  dialogPaper: {
    maxWidth: 400,
    width: 400
  }
};

class RegisterConfirmDialog extends Component {
  static propTypes = {
    open: bool,
    closeDialog: func,
    email: string,
    classes: object
  };

  render() {
    const { open, email, closeDialog, classes } = this.props;

    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Registration Confirmation Sent</DialogTitle>
        <DialogContent>An activation email has been sent to: {email}.</DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={closeDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(RegisterConfirmDialog);
