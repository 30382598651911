import React, { Component } from 'react';
import { bool, func, string, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  dialogPaper: {
    maxHeight: 400,
    width: 400
  }
};

class DeleteProfileDialog extends Component {
  static propTypes = {
    open: bool,
    closeDeleteProfileDialog: func,
    onProfileDelete: func,
    profileToDelete: string,
    classes: object
  };

  render() {
    const {
      onProfileDelete,
      closeDeleteProfileDialog,
      profileToDelete,
      open,
      classes
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={closeDeleteProfileDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>Delete Profile</DialogTitle>
        <DialogContent>
          Are you sure you want to delete <b>{profileToDelete}</b>?
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            key="cancel"
            onClick={closeDeleteProfileDialog}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            key="ok"
            style={{ marginLeft: 10 }}
            onClick={() => {
              closeDeleteProfileDialog();
              onProfileDelete(profileToDelete);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DeleteProfileDialog);
