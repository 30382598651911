import * as ga from '../actions/global';
import defaultState from '../store/defaultGlobalState';

export default function global(state = defaultState, action) {
  switch (action.type) {
    case ga.LOGGED_IN:
      return Object.assign({}, state, { user: action.payload.user });

    case ga.LOGGED_OUT:
      return Object.assign({}, state, { user: null });

    case ga.SUBSCRIBE_USER:
      return Object.assign({}, state, { user: Object.assign({}, state.user, { isPremium: true }) });

    case ga.RE_RENDER:
      return Object.assign({}, state, { renderId: state.renderId + 1 });

    case ga.SET_STATUS: {
      const { status } = action.payload;
      return Object.assign({}, state, {
        status,
        isComparing:
          status.message === 'Launching ExamDiff Pro...'
            ? true
            : status.type === null
            ? false
            : state.isComparing
      });
    }

    case ga.SET_REPORT:
      return {
        ...state,
        reportId: action.payload.reportId,
        durationKey: action.payload.durationKey
      };

    case ga.SET_EXAMDIFF_URI:
      return { ...state, examDiffUri: action.payload.examDiffUri };

    case ga.SET_CLIP_TITLES:
      return Object.assign({}, state, {
        firstClipTitle: action.payload.firstClipTitle,
        firstTitle: action.payload.firstClipTitle,
        secondClipTitle: action.payload.secondClipTitle,
        secondTitle: action.payload.secondClipTitle
      });

    case ga.SET_FILE_TITLES:
      return Object.assign({}, state, {
        firstFileTitle: action.payload.firstFileTitle,
        firstTitle: action.payload.firstFileTitle,
        secondFileTitle: action.payload.secondFileTitle,
        secondTitle: action.payload.secondFileTitle
      });

    case ga.SET_URL_TITLES:
      return Object.assign({}, state, {
        firstUrlTitle: action.payload.firstUrlTitle,
        firstTitle: action.payload.firstUrlTitle,
        secondUrlTitle: action.payload.secondUrlTitle,
        secondTitle: action.payload.secondUrlTitle
      });

    case ga.SET_CLIPS:
      return Object.assign({}, state, {
        firstClip: action.payload.firstClip,
        secondClip: action.payload.secondClip
      });

    case ga.SET_DOC_TYPE:
      return Object.assign({}, state, { docType: action.payload.docType });

    case ga.SET_CLIENT_ID:
      return Object.assign({}, state, { clientId: action.payload.clientId });

    case ga.SET_OPTIONS_PROFILES:
      return Object.assign({}, state, { optionsProfiles: action.payload.optionsProfiles });

    case ga.SET_OPTIONS:
      return Object.assign({}, state, {
        options: action.payload.options,
        optionsLoaded: true
      });

    case ga.SET_NEW_OPTION:
      return Object.assign({}, state, {
        newOptions: Object.assign({}, state.newOptions, action.payload.newOption)
      });

    case ga.SAVE_NEW_OPTIONS:
      return Object.assign({}, state, {
        options: Object.assign({}, state.options, state.newOptions)
      });

    case ga.RESET_NEW_OPTIONS:
      return Object.assign({}, state, { newOptions: {} });

    case ga.SET_DEFAULT_OPTIONS:
      return Object.assign({}, state, { defaultOptions: action.payload.defaultOptions });

    case ga.SET_TRUNCATED_STATUS:
      return Object.assign({}, state, { isTruncated: action.payload.isTruncated });

    case ga.SET_COMPARISON_COUNT:
      return Object.assign({}, state, {
        comparisonCount: action.payload.newComparisonCount,
        comparisonStartDate: action.payload.newComparisonStartDate
      });

    case ga.SET_CONFIGURATION:
      return Object.assign({}, state, {
        configuration: Object.assign({}, action.payload.configuration)
      });

    case ga.SET_URLS:
      return Object.assign({}, state, {
        firstUrl: action.payload.firstUrl,
        secondUrl: action.payload.secondUrl
      });

    case ga.SET_FIRST_FILE:
      return Object.assign({}, state, {
        firstFileStatus: action.payload.firstFileStatus,
        firstFileName: action.payload.firstFileName,
        firstFileUploadedName: action.payload.firstFileUploadedName
      });

    case ga.SET_SECOND_FILE:
      return Object.assign({}, state, {
        secondFileStatus: action.payload.secondFileStatus,
        secondFileName: action.payload.secondFileName,
        secondFileUploadedName: action.payload.secondFileUploadedName
      });

    case ga.SET_LAST_COMPARED_TAB_INDEX:
      return Object.assign({}, state, {
        lastComparedTabIndex: action.payload.lastComparedTabIndex
      });

    case ga.SET_COLLAPSED_TAB_INDEX:
      return Object.assign({}, state, { collapsedTabIndex: action.payload.collapsedTabIndex });

    case ga.SET_THEME:
      return { ...state, theme: action.payload.theme };

    case ga.SET_PREMIUM_SOURCE:
      return { ...state, premiumSource: action.payload.premiumSource };

    case ga.SET_REPORT_READY:
      return { ...state, isReportReady: action.payload.isReportReady };

    default:
      return state;
  }
}
