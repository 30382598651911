import React from 'react';
import { bool, string, array, object } from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const styles = {
  paper: {
    width: 'calc(50% - 10px)'
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 90,
    fontSize: 32,
    fontWeight: 300,
    marginBottom: 10
  },
  fontStandard: {
    backgroundColor: '#EEEEEE',
    color: 'black'
  },
  fontPremium: {
    backgroundColor: '#64B5F6',
    color: 'white'
  },
  featureRow: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  featureContents: {
    padding: 12
  },
  featureSeparator: {
    backgroundColor: '#DDDDDD',
    height: 1,
    width: '90%'
  },
  pricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 64,
    fontWeight: 300,
    fontSize: 24,
    marginTop: 10
  },
  premiumPricingContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  premiumPricingOption: {
    flex: 2,
    textAlign: 'center',
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  premiumPricingSeparator: {
    backgroundColor: 'white',
    width: 1,
    height: '70%'
  },
  premiumPricingHighlighted: {
    padding: '5px 10px',
    borderRadius: 3,
    backgroundColor: '#42A5F5',
    boxShadow: '1px 1px 5px rgba(100, 100, 100, 0.2)'
  },
  premiumPricingUnhighlighted: {
    fontSize: 20
  }
};

const FeatureComparisonPane = ({ classes, title, premium, features }) => (
  <Paper className={classes.paper}>
    <div
      className={classNames(classes.title, {
        [classes.fontStandard]: !premium,
        [classes.fontPremium]: premium
      })}
    >
      {title}
    </div>
    {features.map((feature, index) => (
      <div key={index} className={classes.featureRow}>
        <div dangerouslySetInnerHTML={{ __html: feature }} className={classes.featureContents} />
        {index !== features.length - 1 ? <div className={classes.featureSeparator} /> : null}
      </div>
    ))}
    <div
      className={classNames(classes.pricing, {
        [classes.fontStandard]: !premium,
        [classes.fontPremium]: premium
      })}
    >
      {premium ? (
        <div className={classes.premiumPricingContainer}>
          <div className={classes.premiumPricingOption}>
            <div className={classes.premiumPricingUnhighlighted}>$19.99 / year</div>
          </div>
          <div className={classes.premiumPricingSeparator} />
          <div className={classes.premiumPricingOption}>
            <span className={classes.premiumPricingUnhighlighted}>$2.99 / month</span>
          </div>
        </div>
      ) : (
        <div>FREE</div>
      )}
    </div>
  </Paper>
);

FeatureComparisonPane.propTypes = {
  title: string,
  premium: bool,
  features: array,
  classes: object
};

export default withStyles(styles)(FeatureComparisonPane);
