import React from 'react';
import { object, func, bool } from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import DraggableList from 'react-draggable-list';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';

import { plugins } from '../../util/constants';

import DraggableCheckbox from './DraggableCheckbox';

const PluginOptions = ({ options, setOption, isPremium, openWhyPremiumDialog, theme }) => {
  const styles = {
    optionsBody: {
      padding: 20,
      height: 460,
      position: 'relative'
    },
    optionsGroupHeader: {
      fontSize: 14,
      marginBottom: 8,
      fontWeight: 700,
      color: theme.palette.primary.optionsText,
      display: 'flex',
      alignItems: 'center'
    },
    optionsGroup: {
      marginBottom: 8
    },
    pluginBlocker: {
      position: 'absolute',
      display: !isPremium ? 'block' : 'none',
      top: 0,
      right: 0,
      left: 0,
      height: 400,
      cursor: 'not-allowed',
      zIndex: 20
    },
    mustBePremiumContainer: {
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      right: 10
    },
    premiumStar: {
      marginLeft: 5
    },
    mustBePremiumIcon: {
      fontSize: 14,
      marginRight: 10
    },
    mustBePremiumText: {
      fontSize: 12
    },
    mustBePremiumLink: {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  };

  const sortedPlugins = sortBy(plugins, plugin => options[`${plugin.key}_n`]);

  return (
    <div style={styles.optionsBody}>
      <div style={styles.optionsGroupHeader}>
        Arrange the plug-ins in your desired order of execution.
        {!isPremium && <Icon style={styles.premiumStar}>star</Icon>}
      </div>
      <div style={styles.pluginBlocker} />
      <div style={styles.optionsGroup}>
        <DraggableList
          list={sortedPlugins}
          itemKey="label"
          template={DraggableCheckbox}
          onMoveEnd={newPlugins => {
            forEach(newPlugins, (newPlugin, index) => {
              setOption(`${newPlugin.key}_n`, index);
            });
          }}
          container={() => document.body}
          commonProps={{
            options: options,
            setOption: setOption,
            disabled: !isPremium
          }}
        />
      </div>

      <div
        style={Object.assign({}, styles.mustBePremiumContainer, {
          display: isPremium ? 'none' : 'flex'
        })}
      >
        <Icon style={styles.mustBePremiumIcon}>star</Icon>
        <span style={styles.mustBePremiumText}>
          <span>This feature is only available for&nbsp;</span>
          <a href="#why-premium" onClick={openWhyPremiumDialog} style={styles.mustBePremiumLink}>
            premium
          </a>
          <span>&nbsp;users.</span>
        </span>
      </div>
    </div>
  );
};

PluginOptions.propTypes = {
  isPremium: bool,
  options: object,
  setOption: func,
  openWhyPremiumDialog: func,
  theme: object
};

export default withTheme()(PluginOptions);
