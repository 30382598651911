import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import isFunction from 'lodash/isFunction';

import rootReducer from '../reducers';

export const history = createBrowserHistory();

export default function configureStore(initialState) {
  const enhancers = [];

  const logger = createLogger({
    predicate: (getState, action) => action.type !== '@@router/LOCATION_CHANGE'
  });

  const middleware = [thunk, routerMiddleware(history)];

  if (process.env.NODE_ENV === 'development') {
    const devToolsExtension = window.devToolsExtension;

    if (isFunction(devToolsExtension)) {
      enhancers.push(devToolsExtension());
    }

    middleware.push(logger);
  }

  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  const store = createStore(rootReducer, initialState, composedEnhancers);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
