import React, { Component, Fragment } from 'react';
import { bool, object, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import { Amplitude } from '@amplitude/react-amplitude';

import {
  getPremiumStatus,
  cancelSubscription,
  reactivateSubscription,
  updatePaymentMethod
} from '../../util/subscription';
import CheckoutButton from '../CheckoutButton';

import ConfirmCancelSubscriptionDialog from './ConfirmCancelSubscriptionDialog';

const styles = {
  progressContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 331
  },
  dialogPaper: {
    maxWidth: 725,
    width: 725
  },
  sectionHeader: {
    fontSize: 16,
    marginTop: 12,
    marginBottom: 12,
    color: '#777'
  },
  sectionDivider: {
    marginTop: 12
  },
  paragraph: {
    marginBottom: 5,
    marginLeft: 12,
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cancelButton: {
    borderColor: '#ff1744',
    color: '#ff1744'
  },
  cardBrand: {
    fontSize: 12,
    textTransform: 'capitalize'
  },
  active: {
    fontWeight: 'bold',
    color: 'red'
  },
  inactive: {
    fontWeight: 'bold',
    color: 'green'
  },
  help: {
    marginTop: 10,
    marginLeft: 12,
    fontSize: 12
  },
  loadingError: {
    color: 'red'
  },
  error: {
    fontSize: 12,
    marginTop: 10,
    color: 'red'
  }
};

class ManagePremiumSubscriptionDialog extends Component {
  state = {
    plan: null,
    isCancelled: null,
    currentPeriodStart: null,
    currentPeriodEnd: null,
    cardLastFour: null,
    cardBrand: null,
    showPaymentMethodUpdateToast: false,
    error: null,
    loadingError: null,
    isPremiumStatusLoading: true,
    isConfirmDialogOpen: false
  };

  static propTypes = {
    open: bool,
    user: object,
    closeDialog: func,
    classes: object
  };

  async componentDidUpdate(prevProps) {
    const { open, user } = this.props;

    if (
      open &&
      user &&
      user.email &&
      (open !== prevProps.open || user.email !== prevProps.user.email)
    ) {
      const sessionToken = localStorage.getItem('diffNowSessionToken');

      try {
        this.setState({ isPremiumStatusLoading: true });

        const subscriptionStatus = await getPremiumStatus(sessionToken);
        if (subscriptionStatus.error) {
          this.setState({ loadingError: subscriptionStatus.error, isPremiumStatusLoading: false });
        } else {
          this.setState({
            plan: subscriptionStatus.subscription.PlanName,
            isCancelled: subscriptionStatus.subscription.IsCancelled,
            currentPeriodStart: subscriptionStatus.subscription.CurrentPeriodStart,
            currentPeriodEnd: subscriptionStatus.subscription.CurrentPeriodEnd,
            cardLastFour: subscriptionStatus.subscription.CardLastFour,
            cardBrand: subscriptionStatus.subscription.CardBrand,
            error: null,
            isPremiumStatusLoading: false
          });
        }
      } catch (error) {
        this.setState({ loadingError: error, isPremiumStatusLoading: false });
      }
    }
  }

  handleCancelSubscription = () => {
    const sessionToken = localStorage.getItem('diffNowSessionToken');

    cancelSubscription(
      sessionToken,
      cancelSubscriptionStatus => {
        if (cancelSubscriptionStatus.error) {
          this.setState({ error: cancelSubscriptionStatus.error });
        } else {
          this.setState({ isCancelled: true });
        }
      },
      error => {
        this.setState({ error: error });
      }
    );
  };

  handleReactivateSubscription = () => {
    const sessionToken = localStorage.getItem('diffNowSessionToken');

    reactivateSubscription(
      sessionToken,
      reactivateSubscriptionStatus => {
        if (reactivateSubscriptionStatus.error) {
          this.setState({ error: reactivateSubscriptionStatus.error });
        } else {
          this.setState({ isCancelled: false });
        }
      },
      error => {
        this.setState({ error: error });
      }
    );
  };

  handlePaymentMethodUpdate = tokenId => {
    const sessionToken = localStorage.getItem('diffNowSessionToken');

    updatePaymentMethod(
      sessionToken,
      tokenId,
      updatePaymentMethodStatus => {
        if (updatePaymentMethodStatus.error) {
          this.setState({ error: updatePaymentMethodStatus.error });
        } else {
          this.setState({ showPaymentMethodUpdateToast: true });
          this.props.closeDialog();
        }
      },
      error => {
        this.setState({ error: error });
      }
    );
  };

  handleOpenConfirmDialog = () => {
    this.setState({ isConfirmDialogOpen: true });
  };

  handleCloseConfirmDialog = () => {
    this.setState({ isConfirmDialogOpen: false });
  };

  handleClosePaymentMethodUpdateToast = () => {
    this.setState({ showPaymentMethodUpdateToast: false });
  };

  render() {
    const { open, user, closeDialog, classes } = this.props;
    const subscriptionPlans = {
      'diffnow-yearly-plan': 'Yearly',
      'diffnow-monthly-plan': 'Monthly',
      'diffnow-monthly-plan-299': 'Monthly'
    };
    const parseDate = date => new Date(date).toLocaleDateString();

    const dialogContent = this.state.isPremiumStatusLoading ? (
      <CircularProgress />
    ) : (
      <div>
        <div className={classes.sectionHeader}>Your Subscription</div>

        <div className={classes.paragraph}>
          Current subscription plan: {subscriptionPlans[this.state.plan]}
        </div>

        <div className={classes.paragraph}>
          Current subscription period: {parseDate(this.state.currentPeriodStart)} -{' '}
          {parseDate(this.state.currentPeriodEnd)}
        </div>

        <Divider classes={{ root: classes.sectionDivider }} />

        <div className={classes.sectionHeader}>Payment Information</div>

        <div className={classes.paragraph}>
          <div>
            <span>Your current payment method is:&nbsp;</span>
            <span>
              ●●●● ●●●● ●●●● {this.state.cardLastFour}{' '}
              <span className={classes.cardBrand}>({this.state.cardBrand})</span>
            </span>
          </div>

          <div>
            <Amplitude>
              {({ logEvent }) => (
                <CheckoutButton
                  key="checkout"
                  isPaymentMethodUpdate
                  subscriptionType={this.state.selectedPriceModel}
                  email={user ? user.email : ''}
                  onCheckout={token => {
                    logEvent('UPDATE_PAYMENT_METHOD');
                    this.handlePaymentMethodUpdate(token.id);
                  }}
                >
                  <Button color="primary" variant="outlined">
                    Update Payment Method
                  </Button>
                </CheckoutButton>
              )}
            </Amplitude>
          </div>
        </div>

        <Divider classes={{ root: classes.sectionDivider }} />

        <div className={classes.sectionHeader}>Cancel Subscription</div>

        <div className={classes.paragraph}>
          <div>
            <span>Your subscription is:&nbsp;</span>
            <span className={this.state.isCancelled ? classes.active : classes.inactive}>
              {this.state.isCancelled ? 'Inactive' : 'Active'}
            </span>
          </div>

          <div>
            <Amplitude>
              {({ logEvent }) => (
                <>
                  {this.state.loadingError ? null : this.state.isCancelled ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        logEvent('REACTIVATE_SUBSCRIPTION');
                        this.handleReactivateSubscription();
                      }}
                    >
                      Reactivate Subscription
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      classes={{ outlined: classes.cancelButton }}
                      onClick={() => {
                        logEvent('CANCEL_SUBSCRIPTION');
                        this.handleOpenConfirmDialog();
                      }}
                    >
                      Cancel Subscription
                    </Button>
                  )}
                </>
              )}
            </Amplitude>
          </div>
        </div>

        <div className={classes.help}>
          {this.state.isCancelled ? (
            <span>
              Reactivating your subscription will not alter your current subscription and will
              reinstate future recurring subscriptions.
            </span>
          ) : (
            <span>
              Cancelling your subscription will not end your current subscription period, but will
              stop future recurring payments and subscriptions. You can reactivate your subscription
              at any time.
            </span>
          )}
        </div>
      </div>
    );

    return (
      <Fragment>
        <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle>Manage Premium Subscription</DialogTitle>

          <DialogContent
            classes={{
              root: this.state.isPremiumStatusLoading ? classes.progressContainer : undefined
            }}
          >
            {this.state.loadingError ? (
              <div className={classes.loadingError}>{this.state.loadingError}</div>
            ) : (
              dialogContent
            )}
            {this.state.error && <div className={classes.error}>{this.state.error}</div>}
          </DialogContent>
          <DialogActions>
            <Button color="primary" variant="outlined" onClick={closeDialog}>
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmCancelSubscriptionDialog
          open={this.state.isConfirmDialogOpen}
          handleClose={this.handleCloseConfirmDialog}
          cancelSubscription={this.handleCancelSubscription}
        />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={this.state.showPaymentMethodUpdateToast}
          autoHideDuration={6000}
          onClose={this.handleClosePaymentMethodUpdateToast}
          message={<span id="message-id">Payment method updated successfully!</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClosePaymentMethodUpdateToast}
            >
              <Icon>close</Icon>
            </IconButton>
          ]}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles)(ManagePremiumSubscriptionDialog);
