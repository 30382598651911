import React, { Component } from 'react';
import { object, string, func } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
  checkBoxRoot: {
    width: 16,
    height: 16,
    margin: '8px 16px'
  }
};

class ColorPickerCheckbox extends Component {
  static propTypes = {
    option: object,
    textDecoration: string,
    optionKey: string,
    setOption: func,
    classes: object
  };

  render() {
    const { option, textDecoration, optionKey, setOption, classes } = this.props;

    return (
      <Checkbox
        color="primary"
        checked={option[textDecoration]}
        className={classes.checkBoxRoot}
        onChange={event => {
          setOption(
            optionKey,
            Object.assign({}, option, { [textDecoration]: event.target.checked })
          );
        }}
      />
    );
  }
}

export default withStyles(styles)(ColorPickerCheckbox);
