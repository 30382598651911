import axios from 'axios';
import queryString from 'query-string';

import { DIFFNOW_API_URL } from './constants';

const REPORTS_URL = `${DIFFNOW_API_URL}/reports`;

export async function getSavedReports(sessionToken) {
  try {
    const response = await axios.get(`${REPORTS_URL}/getUserReports?sessionToken=${sessionToken}`);
    return response.data.reports;
  } catch (err) {
    if (err.response) {
      throw err.response.data.error;
    } else {
      throw err.message;
    }
  }
}

export async function unrollReport(shortUrl) {
  try {
    return (await axios.get(`${REPORTS_URL}/unrollReport?shortUrl=${shortUrl}`)).data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data);
    } else {
      throw new Error(error.message);
    }
  }
}

export function deleteUserReport(shortUrl, sessionToken, onSuccess, onError) {
  axios
    .post(`${REPORTS_URL}/deleteUserReport`, {
      sessionToken: sessionToken,
      shortUrl: shortUrl
    })
    .then(response => {
      onSuccess(response.data.result);
    })
    .catch(err => {
      if (err.response) {
        onError(err.response.data);
      } else {
        onError(err.message);
      }
    });
}

export function renameUserReport(shortUrl, newTitle, sessionToken, onSuccess, onError) {
  axios
    .post(`${REPORTS_URL}/renameUserReport`, {
      shortUrl,
      newTitle,
      sessionToken
    })
    .then(response => {
      onSuccess(response.data.result);
    })
    .catch(err => {
      if (err.response) {
        onError(err.response.data.error);
      } else {
        onError(err.message);
      }
    });
}

export function storeResults(duration, title, reportId, sessionToken, onSuccess, onError) {
  axios
    .post(`${REPORTS_URL}/storeResults`, {
      duration,
      title,
      reportId,
      sessionToken
    })
    .then(response => {
      onSuccess(response.data.shortUrlIndex);
    })
    .catch(err => {
      if (err.response) {
        onError(err.response.data.error);
      } else {
        onError(err.message);
      }
    });
}

export function getReportMetadata(shortUrl, onSuccess, onError) {
  axios
    .get(`${REPORTS_URL}/getReportMetadata?shortUrl=${shortUrl}`)
    .then(response => {
      onSuccess(response.data.report);
    })
    .catch(err => {
      if (err.response) {
        onError(err.response.data.error);
      } else {
        onError(err.message);
      }
    });
}

export async function getReport(reportId, durationKey) {
  return (await axios.get(generateReportUrl(reportId, durationKey))).data;
}

export function generateReportUrl(reportId, durationKey = '') {
  const queryParameters = queryString.stringify({ reportId, durationKey });
  return `${REPORTS_URL}/getReport?${queryParameters}`;
}
