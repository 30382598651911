import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { sendMail } from '../../util/mail';

const styles = {
  dialogPaper: {
    maxHeight: 550,
    width: 550
  }
};

class ContactDialog extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
    findOut: '',
    error: null
  };

  static propTypes = {
    open: bool,
    closeDialog: func,
    classes: object
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  sendContactMail = () => {
    sendMail(
      this.state.name,
      this.state.name,
      this.state.email,
      this.state.subject,
      this.state.message,
      this.state.findOut,
      this.props.closeDialog,
      errorMessage => {
        this.setState({ error: errorMessage });
      }
    );
  };

  render() {
    const { open, closeDialog, classes } = this.props;
    const style = { width: '100%', marginBottom: 5 };
    const { error } = this.state;

    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Contact Us</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <TextField
              id="name"
              value={this.state.name}
              onChange={this.handleChange('name')}
              label="Name"
              style={style}
            />
            <TextField
              id="email"
              value={this.state.email}
              onChange={this.handleChange('email')}
              label="Email Address"
              style={style}
            />
            <TextField
              id="subject"
              value={this.state.subject}
              onChange={this.handleChange('subject')}
              label="Subject"
              style={style}
            />
            <TextField
              id="message"
              value={this.state.message}
              onChange={this.handleChange('message')}
              label="Message"
              style={style}
              multiline
              rows="4"
            />
            <TextField
              id="findOut"
              value={this.state.findOut}
              onChange={this.handleChange('findOut')}
              label="How did you find out about DiffNow?"
              style={style}
            />
          </form>
          {error && (
            <div
              style={{
                fontSize: 12,
                marginTop: 10,
                color: 'red'
              }}
            >
              {error}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button key="close" onClick={closeDialog} color="primary">
            Cancel
          </Button>
          ,
          <Button key="submit" variant="raised" onClick={this.sendContactMail} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ContactDialog);
