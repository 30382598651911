import React, { Component } from 'react';
import { string, func, object } from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';

import { replaceTags } from '../util/messages';

class Status extends Component {
  static propTypes = {
    message: string,
    type: string,
    removeTip: func,
    theme: object,
    openWhyPremiumDialog: func,
    openOptionsDialog: func
  };

  state = {
    type: null,
    message: null
  };

  render() {
    const message = this.state.message || this.props.message;
    const type = this.state.type || this.props.type;

    const color = type && this.props.theme.palette.status[type].color;
    const backgroundColor = type && this.props.theme.palette.status[type].backgroundColor;
    let messageFormatted = (
      <span>
        {replaceTags(message, this.props.openWhyPremiumDialog, this.props.openOptionsDialog)}
      </span>
    );
    let icon = null;
    let rightIcon = null;

    if (type === 'error') {
      icon = <Icon style={{ color: color }}>error</Icon>;
    } else if (type === 'success') {
      icon = <Icon style={{ color: color }}>check</Icon>;
    } else if (type === 'tip') {
      icon = <Icon style={{ color: color }}>info</Icon>;
      messageFormatted = (
        <span>
          <b>Did you know? </b>
          <span>
            {replaceTags(message, this.props.openWhyPremiumDialog, this.props.openOptionsDialog)}
          </span>
        </span>
      );
      rightIcon = (
        <Icon style={{ color: color }} onClick={this.props.removeTip}>
          clear
        </Icon>
      );
    } else if (type === 'info') {
      icon = <Icon style={{ color: color }}>info</Icon>;
    }

    return (
      <Paper
        style={{
          display: message ? 'flex' : 'none',
          alignItems: 'center',
          padding: 10,
          marginTop: 20,
          backgroundColor: backgroundColor,
          color: color,
          justifyContent: 'space-between'
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {icon}
          <div id="status-message" style={{ marginLeft: 10 }}>
            {messageFormatted}
          </div>
        </div>
        {rightIcon}
      </Paper>
    );
  }
}

export default withTheme()(Status);
