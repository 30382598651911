import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  dialogPaper: {
    maxHeight: 650,
    width: 650
  }
};

class TermsOfServiceDialog extends Component {
  static propTypes = {
    open: bool,
    closeDialog: func,
    classes: object
  };

  render() {
    const { open, closeDialog, classes } = this.props;

    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Terms of Service</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: 15 }}>
            At{' '}
            <a
              href="http://www.prestosoft.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginRight: 2 }}
            >
              PrestoSoft LLC
            </a>
            we value and protect your privacy. These Terms of Service explain how we collect, use,
            and protect information collected from users (each, a &quot;User&quot;) of the
            www.diffnow.com website (&quot;Site&quot;).
          </div>
          <div style={{ marginBottom: 15 }}>
            <b>How we use uploaded data</b>
          </div>
          <div style={{ marginBottom: 15 }}>
            As part of comparison, User may upload files or clipboard contents to the Site. We
            delete all uploaded data from the Site during file comparison or, if no comparison is
            subsequently performed, at the end of the browsing session. Comparison results are
            deleted from the Site at the end of the browsing session.
          </div>
          <div style={{ marginBottom: 15 }}>
            We collect only the following information for quality assurance purposes: the names of
            the files or URLs compared by User and whether and when any errors occurred during
            comparison.
          </div>
          <div style={{ marginBottom: 15 }}>
            The User shall not upload data of a sensitive nature as part of User{"'"}s interaction
            with the Site.
          </div>
          <div style={{ marginBottom: 15 }}>
            If the User utilizes the Site to save comparison reports for future reuse, we do not
            share these reports with third parties. Only the User has the ability to view or share
            their reports. The user can also delete their saved reports at any point in time.
          </div>
          <div style={{ marginBottom: 15 }}>
            <b>How we use your email address</b>
          </div>
          <div style={{ marginBottom: 15 }}>
            We may use your email address to send announcements about new DiffNow features. We will
            not pass or sell your email address to third parties.
          </div>
          <div style={{ marginBottom: 15 }}>
            <b>Web browser cookies</b>
          </div>
          <div style={{ marginBottom: 15 }}>
            Our Site may use &quot;cookies&quot; to enhance User experience. User{"'"}s web browser
            places cookies on their hard drive for record-keeping purposes and sometimes to track
            information about them. User may choose to set their web browser to refuse cookies, or
            to alert you when cookies are being sent. If they do so, note that some parts of the
            Site may not function properly.
          </div>
          <div style={{ marginBottom: 15 }}>
            <b>How we protect your information</b>
          </div>
          <div style={{ marginBottom: 15 }}>
            We adopt appropriate data collection, storage and processing practices and security
            measures to protect against unauthorized access, alteration, disclosure or destruction
            of your personal information, username, password, transaction information and data
            stored on our Site.
          </div>
          <div style={{ marginBottom: 15 }}>
            <b>Sharing your personal information</b>
          </div>
          <div style={{ marginBottom: 15 }}>
            We do not sell, trade, or rent User{"'"}s personal identification information to others.
          </div>
          <div style={{ marginBottom: 15 }}>
            <b>Changes to these terms of service</b>
          </div>
          <div style={{ marginBottom: 15 }}>
            We have the discretion to update these terms of service at any time. We encourage Users
            to frequently check this page for any changes to stay informed about how we are helping
            to protect the personal information we collect. You acknowledge and agree that it is
            your responsibility to review this privacy policy periodically and become aware of
            modifications.
          </div>
          <div style={{ marginBottom: 15 }}>
            <b>Your acceptance of these terms</b>
          </div>
          <div style={{ marginBottom: 15 }}>
            By using this Site, you signify your acceptance of this policy. If you do not agree to
            this policy, please do not use our Site. Your continued use of the Site following the
            posting of changes to this policy will be deemed your acceptance of those changes.
          </div>
          <div style={{ marginBottom: 15 }}>
            <b>Contacting us</b>
          </div>
          <div style={{ marginBottom: 15 }}>
            If you have any questions about these Terms of Service, please contact us at:
          </div>
          <div style={{ marginBottom: 15 }}>
            <div>PrestoSoft LLC</div>
            <div>P.O. Box 3611</div>
            <div>Thousand Oaks, CA 91359</div>
            <div>diffnow@prestosoft.com</div>
          </div>
          <div>This document was last updated in 2018.</div>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={closeDialog}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(TermsOfServiceDialog);
