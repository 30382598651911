import React from 'react';
import { object, func, bool } from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Icon from '@material-ui/core/Icon';

import { validateNumber } from '../../util/validation';

import OptionsCheckbox from './OptionsCheckbox';
import OptionsTextfield from './OptionsTextfield';
import OptionsCheckboxTextfield from './OptionsCheckboxTextfield';

const ResultsOptions = props => {
  const styles = {
    optionsBody: {
      padding: 20,
      height: 460,
      position: 'relative'
    },
    optionsGroupHeader: {
      fontSize: 14,
      marginTop: 8,
      marginBottom: 8,
      fontWeight: 700,
      color: props.theme.palette.primary.optionsText
    },
    checkboxGroup: {
      display: 'flex',
      alignItems: 'center',
      height: 32
    },
    smallCheckbox: {
      width: '25%'
    },
    mediumCheckbox: {
      width: '50%'
    },
    largeCheckbox: {
      width: '75%'
    },
    radioButton: {
      marginBottom: 8
    },
    mustBePremiumContainer: {
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      right: 10
    },
    mustBePremiumIcon: {
      fontSize: 14,
      marginRight: 10
    },
    mustBePremiumText: {
      fontSize: 12
    },
    mustBePremiumLink: {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  };

  const { options, setOption, isPremium, openWhyPremiumDialog } = props;

  return (
    <div style={styles.optionsBody}>
      <div style={styles.optionsGroupHeader}>Results</div>
      <div style={styles.optionsGroup}>
        <div style={styles.checkboxGroup}>
          <OptionsCheckbox
            checked={options['hrz']}
            option="hrz"
            setOption={setOption}
            columns={2}
          />
          <OptionsCheckbox
            checked={options['sli']}
            option="sli"
            setOption={setOption}
            columns={2}
          />
        </div>
        <div style={styles.checkboxGroup}>
          <OptionsCheckbox
            checked={options['vws']}
            option="vws"
            setOption={setOption}
            columns={2}
          />
        </div>
        <div style={styles.checkboxGroup}>
          <OptionsTextfield
            value={options['lcl']}
            option="lcl"
            setOption={setOption}
            disabled={!isPremium}
            error={validateNumber(options['lcl'])}
          />
        </div>
      </div>
      <div style={styles.optionsGroupHeader}>Word wrap</div>
      <div style={styles.optionsGroup}>
        <div style={styles.checkboxGroup}>
          <OptionsCheckboxTextfield
            checked={options['uww']}
            checkboxOption="uww"
            value={options['www']}
            option="www"
            setOption={setOption}
            disabled={!isPremium}
            error={validateNumber(options['www'])}
          />
        </div>
      </div>
      <div style={styles.optionsGroupHeader}>Detail level for text file differences</div>
      <div style={styles.optionsGroup}>
        <RadioGroup
          name="detail-level"
          value={JSON.stringify(options['det'])}
          onChange={e => setOption('det', Number(e.target.value))}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel
            value="0"
            control={<Radio color="primary" style={{ height: 36 }} />}
            label="Lines"
          />
          <FormControlLabel
            value="1"
            control={<Radio color="primary" style={{ height: 36 }} />}
            label="Lines and words"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" style={{ height: 36 }} />}
            label="Lines and chars"
          />
        </RadioGroup>
      </div>
      <div style={styles.optionsGroupHeader}>Lines to show</div>
      <div style={styles.optionsGroup}>
        <RadioGroup
          name="lines-to-show"
          value={JSON.stringify(options['uvf'])}
          onChange={e => setOption('uvf', Number(e.target.value))}
          style={{ flexDirection: 'row' }}
        >
          <FormControlLabel
            value="0"
            control={<Radio color="primary" style={{ height: 36 }} />}
            label="All lines"
          />
          <FormControlLabel
            value="1"
            control={<Radio color="primary" style={{ height: 36 }} />}
            label="Only different lines"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" style={{ height: 36 }} />}
            label="Only identical lines"
          />
        </RadioGroup>
      </div>

      <div
        style={Object.assign({}, styles.mustBePremiumContainer, {
          display: isPremium ? 'none' : 'flex'
        })}
      >
        <Icon style={styles.mustBePremiumIcon}>star</Icon>
        <span style={styles.mustBePremiumText}>
          <span>This feature is only available for&nbsp;</span>
          <a href="#why-premium" onClick={openWhyPremiumDialog} style={styles.mustBePremiumLink}>
            premium
          </a>
          <span>&nbsp;users.</span>
        </span>
      </div>
    </div>
  );
};

ResultsOptions.propTypes = {
  isPremium: bool,
  options: object,
  setOption: func,
  openWhyPremiumDialog: func,
  theme: object
};

export default withTheme()(ResultsOptions);
