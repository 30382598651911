import React, { Component, Fragment } from 'react';
import { bool, func, array, object } from 'prop-types';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const styles = {
  dialogPaper: {
    maxHeight: 600,
    width: 600
  }
};

class ChangeLogDialog extends Component {
  static propTypes = {
    open: bool,
    closeDialog: func,
    changeLog: array,
    classes: object,
    theme: object
  };

  render() {
    const { open, closeDialog, changeLog, classes, theme } = this.props;

    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>
          What&#39;s New in DiffNow
          <div
            style={{
              fontSize: 12,
              color: theme.palette.disclaimerTextColor
            }}
          >
            <Icon style={{ fontSize: 12, color: theme.palette.disclaimerTextColor }}>star</Icon> =
            DiffNow Premium users only
          </div>
        </DialogTitle>
        <DialogContent>
          {changeLog.map((versionChanges, versionChangesIndex) => (
            <Fragment key={versionChangesIndex}>
              <div>
                <b style={{ fontSize: 18 }}>v{versionChanges.version}</b> -{' '}
                <i style={{ fontSize: 14 }}>{versionChanges.date}</i>
              </div>
              <ul>
                {versionChanges.changes.map((changes, changesIndex) => (
                  <li key={changesIndex} style={{ margin: '10px 0' }}>
                    <span dangerouslySetInnerHTML={{ __html: changes.feature }} />
                    {changes.premiumOnly && (
                      <Icon style={{ fontSize: 14, marginLeft: 5, color: '#444' }}>star</Icon>
                    )}
                  </li>
                ))}
              </ul>
            </Fragment>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            id="close-changelog-dialog-button"
            variant="outlined"
            color="primary"
            onClick={() => {
              closeDialog();
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withTheme()(withStyles(styles)(ChangeLogDialog));
