import { inBrowser } from './browser';

export function id() {
  return Math.random()
    .toString(36)
    .slice(2, 16);
}

export function examDiffProUrl(referrer) {
  return `https://www.prestosoft.com/edp_examdiffpro.asp?ref=${referrer}`;
}

export function toDateString(date) {
  const dateObject = new Date(date);
  return `${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`;
}

export const isPremium = user => !!(user && user.isPremium);

export function comparisonLimit(user, configuration) {
  const {
    comparisonCountLimitNotLoggedInMax,
    monthlyComparisonCountLimitMax,
    useMonthlyComparisonLimits
  } = configuration;
  const maximumComparisons = useMonthlyComparisonLimits
    ? monthlyComparisonCountLimitMax
    : comparisonCountLimitNotLoggedInMax;

  if (isPremium(user)) {
    return Infinity;
  } else {
    return maximumComparisons;
  }
}

export function canUserCompare(comparisonCount, user, configuration) {
  return comparisonCount < comparisonLimit(user, configuration);
}

export function isBrowserUnsupported() {
  require('../lib/modernizr.js'); // eslint-disable-line
  const { Modernizr } = window;
  return inBrowser() && !(Modernizr.es5 && Modernizr.flexbox && Modernizr.localstorage);
}

export const sleep = async ms => new Promise(resolve => setTimeout(resolve, ms));
