/* eslint no-restricted-globals: ["off", "location"] */
import React, { Component, Fragment } from 'react';
import { string, bool, func, object } from 'prop-types';
import reject from 'lodash/reject';
import { withStyles, withTheme } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { getSavedReports, deleteUserReport, renameUserReport } from '../../util/reports';
import { toDateString } from '../../util/common';
import ConfirmDeleteReportDialog from '../dialogs/ConfirmDeleteReportDialog';
import RenameReportDialog from '../dialogs/RenameReportDialog';

const styles = {
  dialogPaper: {
    maxHeight: 400,
    width: 750,
    maxWidth: 750,
    overflowY: 'auto'
  }
};

class SavedReportsDialog extends Component {
  state = {
    savedReports: [],
    selectedShortUrl: null,
    selectedReportTitle: null,
    confirmDeleteReportDialogOpen: false,
    renameReportDialogOpen: false
  };

  static propTypes = {
    open: bool,
    closeDialog: func,
    onSetStatus: func,
    sessionToken: string,
    classes: object,
    theme: object
  };

  async componentDidUpdate(prevProps) {
    const { open, closeDialog, sessionToken } = this.props;

    if (open !== prevProps.open) {
      if (open) {
        try {
          const savedReports = await getSavedReports(sessionToken);
          this.setState({ savedReports });
        } catch (error) {
          closeDialog();
          this.props.onSetStatus({
            type: 'error',
            message: `Failed to load saved reports: ${error}`
          });
        }
      } else {
        this.setState({ savedReports: [] });
      }
    }
  }

  openReport = shortUrl => {
    window.open(`/report/${shortUrl}`, '_blank');
  };

  deleteReport = shortUrl => {
    deleteUserReport(
      shortUrl,
      this.props.sessionToken,
      () => {
        this.setState(prevState => ({
          savedReports: reject(prevState.savedReports, ['YourlsKeyword', shortUrl])
        }));
      },
      console.error
    );
  };

  renameReport = (shortUrl, newReportTitle, onSuccess, onError) => {
    renameUserReport(
      shortUrl,
      newReportTitle,
      this.props.sessionToken,
      () => {
        this.setState(prevState => ({
          savedReports: prevState.savedReports.map(savedReport => {
            if (savedReport.YourlsKeyword === shortUrl) {
              return Object.assign({}, savedReport, { Title: newReportTitle });
            } else {
              return savedReport;
            }
          })
        }));
        onSuccess();
      },
      onError
    );
  };

  openConfirmDeleteReportDialog = (shortUrl, reportTitle) => {
    this.setState({
      selectedReportTitle: reportTitle,
      selectedShortUrl: shortUrl,
      confirmDeleteReportDialogOpen: true
    });
  };

  openRenameReportDialog = (shortUrl, reportTitle) => {
    this.setState({
      selectedReportTitle: reportTitle,
      selectedShortUrl: shortUrl,
      renameReportDialogOpen: true
    });
  };

  renderSavedReports = () => (
    <Table style={{ width: 700 }}>
      <TableHead>
        <TableRow>
          <TableCell padding="none">Report</TableCell>
          <TableCell padding="none">Comparison Time</TableCell>
          <TableCell padding="none">Expiration Time</TableCell>
          <TableCell padding="checkbox" style={this.styles.iconColumn}>
            Open
          </TableCell>
          <TableCell padding="checkbox" style={this.styles.iconColumn}>
            Delete
          </TableCell>
          <TableCell padding="checkbox" style={this.styles.iconColumn}>
            Rename
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {this.state.savedReports.map((report, index) => (
          <TableRow key={index}>
            <TableCell padding="none">{report.Title}</TableCell>
            <TableCell padding="none">{toDateString(report.GenerationTimeString)}</TableCell>
            <TableCell padding="none">
              {report.ExpirationTime === null ? 'Never' : toDateString(report.ExpirationTimeString)}
            </TableCell>
            <TableCell padding="checkbox" style={this.styles.iconColumn}>
              <Icon
                onClick={() => this.openReport(report.YourlsKeyword)}
                style={this.styles.actionIcon}
              >
                open_in_new
              </Icon>
            </TableCell>
            <TableCell padding="checkbox" style={this.styles.iconColumn}>
              <Icon
                onClick={() =>
                  this.openConfirmDeleteReportDialog(report.YourlsKeyword, report.Title)
                }
                style={this.styles.actionIcon}
              >
                delete
              </Icon>
            </TableCell>
            <TableCell padding="checkbox" style={this.styles.iconColumn}>
              <Icon
                onClick={() => this.openRenameReportDialog(report.YourlsKeyword, report.Title)}
                style={this.styles.actionIcon}
              >
                edit
              </Icon>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  get styles() {
    return {
      iconColumn: {
        width: 60,
        boxSizing: 'border-box',
        textAlign: 'center'
      },
      actionIcon: {
        cursor: 'pointer',
        color: this.props.theme.palette.iconColor
      }
    };
  }

  render() {
    const { open, closeDialog, classes } = this.props;

    return (
      <Fragment>
        <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
          <DialogTitle>Your Saved Reports</DialogTitle>
          <DialogContent>
            <div style={{ maxHeight: 400, overflowY: 'auto', width: 700 }}>
              {this.renderSavedReports()}
            </div>
          </DialogContent>
          <DialogActions>
            <Button key="close" color="primary" variant="outlined" onClick={closeDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <ConfirmDeleteReportDialog
          shortUrl={this.state.selectedShortUrl}
          reportTitle={this.state.selectedReportTitle}
          deleteReport={this.deleteReport}
          handleClose={() =>
            this.setState({
              confirmDeleteReportDialogOpen: false,
              selectedReportTitle: null,
              selectedShortUrl: null
            })
          }
          open={this.state.confirmDeleteReportDialogOpen}
        />
        <RenameReportDialog
          shortUrl={this.state.selectedShortUrl}
          reportTitle={this.state.selectedReportTitle}
          renameReport={this.renameReport}
          handleClose={() =>
            this.setState({
              renameReportDialogOpen: false,
              selectedReportTitle: null,
              selectedShortUrl: null
            })
          }
          open={this.state.renameReportDialogOpen}
        />
      </Fragment>
    );
  }
}

export default withTheme()(withStyles(styles)(SavedReportsDialog));
