import React, { Component, Fragment } from 'react';
import { bool, func, string, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { Amplitude } from '@amplitude/react-amplitude';

import { storeResults } from '../../util/reports';

const styles = {
  dialogPaper: {
    maxHeight: 550,
    width: 550
  }
};

class StoreResultsDialog extends Component {
  state = {
    title: undefined,
    duration: 'week',
    loading: false,
    shareLink: '',
    error: null
  };

  static propTypes = {
    open: bool,
    reportId: string,
    firstTitle: string,
    secondTitle: string,
    user: object,
    closeDialog: func,
    classes: object
  };

  resetState = () => {
    this.setState({
      title: undefined,
      duration: 'week',
      loading: false,
      shareLink: '',
      error: null
    });
  };

  storeResults = () => {
    const { reportId, user } = this.props;
    const { title, duration } = this.state;
    const currentTitle = title || this.defaultTitle;

    this.setState({ loading: true });

    storeResults(
      duration,
      currentTitle,
      reportId,
      user.sessionToken,
      result => {
        this.setState({ shareLink: `/report/${result}`, loading: false });
      },
      error => {
        this.setState({ error: error, loading: false });
      }
    );
  };

  get defaultTitle() {
    const firstTitle = this.props.firstTitle === '' ? 'Clip 1' : this.props.firstTitle;
    const secondTitle = this.props.secondTitle === '' ? 'Clip 2' : this.props.secondTitle;
    return `${firstTitle} - ${secondTitle}`;
  }

  get actions() {
    const { closeDialog } = this.props;
    const { title, shareLink, loading } = this.state;

    if (shareLink) {
      return (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            this.resetState();
            closeDialog();
          }}
        >
          OK
        </Button>
      );
    } else {
      return (
        <Fragment>
          <Button
            key="cancel"
            variant="outlined"
            style={{ marginRight: 10 }}
            onClick={() => {
              this.resetState();
              closeDialog();
            }}
          >
            Cancel
          </Button>
          <Amplitude>
            {({ logEvent }) => (
              <Button
                key="store"
                variant="raised"
                disabled={loading || title === ''}
                color="primary"
                onClick={() => {
                  logEvent('SAVE_REPORT', { duration: this.state.duration });
                  this.storeResults();
                }}
              >
                Store
              </Button>
            )}
          </Amplitude>
        </Fragment>
      );
    }
  }

  get body() {
    const { duration, shareLink, loading, error } = this.state;

    if (!shareLink && !loading) {
      return (
        <div>
          <div style={{ marginBottom: 15 }}>
            Click the button below to store this comparison report on our servers and receive a
            permalink, so you can share it with colleagues. You can view all of your saved reports
            by using the <b>Saved Reports</b> command.
          </div>
          <div style={{ display: 'flex' }}>
            <TextField
              style={{ flex: '3 3' }}
              id="title"
              label="Report Title"
              value={this.state.title === undefined ? this.defaultTitle : this.state.title}
              onChange={e => this.setState({ title: e.target.value })}
            />
            <FormControl>
              <InputLabel htmlFor="duration" style={{ marginLeft: 10 }}>
                Duration
              </InputLabel>
              <Select
                style={{ flex: '1 1', marginLeft: 10 }}
                value={this.state.duration}
                onChange={e => this.setState({ duration: e.target.value })}
                input={<Input id="duration" />}
              >
                <MenuItem value={'hour'}>1 hour</MenuItem>
                <MenuItem value={'day'}>1 day</MenuItem>
                <MenuItem value={'week'}>1 week</MenuItem>
                <MenuItem value={'month'}>1 month</MenuItem>
                <MenuItem value={'year'}>1 year</MenuItem>
                <MenuItem value={'forever'}>forever</MenuItem>
              </Select>
            </FormControl>
          </div>
          {error && (
            <div
              style={{
                fontSize: 12,
                marginTop: 10,
                color: 'red'
              }}
            >
              {error}
            </div>
          )}
        </div>
      );
    } else if (loading) {
      return (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress size={50} />
        </div>
      );
    } else {
      const durationString = duration === 'forever' ? duration : `one ${duration}`;
      const fullShareLink = `${window.location.origin}${shareLink}`;

      return (
        <div>
          <div>{`Your comparison report will be stored for ${durationString}. Your unique report URL is:`}</div>
          <div style={{ marginTop: 10, textAlign: 'center' }}>
            <a href={fullShareLink} target="_blank" rel="noopener noreferrer">
              {fullShareLink}
            </a>
          </div>
        </div>
      );
    }
  }

  render() {
    const { open, classes, closeDialog } = this.props;

    return (
      <Dialog open={open} onClose={closeDialog} classes={{ paper: classes.dialogPaper }}>
        <DialogTitle>Share Results</DialogTitle>
        <DialogContent>{this.body}</DialogContent>
        <DialogActions>{this.actions}</DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(StoreResultsDialog);
