import React from 'react';
import { bool, object, func } from 'prop-types';
import { withTheme } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import ReactTooltip from 'react-tooltip';

import { optionsTooltips } from '../../util/constants';
import { validateRange, validateRegex } from '../../util/validation';

import OptionsCheckbox from './OptionsCheckbox';
import OptionsTextfield from './OptionsTextfield';

const CompareOptions = props => {
  const styles = {
    optionsBody: {
      padding: '10px 20px',
      height: 460,
      position: 'relative'
    },
    optionsGroupHeader: {
      fontSize: 14,
      marginTop: 8,
      marginBottom: 8,
      fontWeight: 700,
      color: props.theme.palette.primary.optionsText,
      display: 'inline-block'
    },
    checkboxGroup: {
      display: 'flex',
      height: 32
    },
    checkboxTextField: {
      display: 'flex',
      height: 32,
      alignItems: 'center'
    },
    mustBePremiumContainer: {
      alignItems: 'center',
      position: 'absolute',
      bottom: 0,
      right: 10
    },
    mustBePremiumIcon: {
      fontSize: 14,
      marginRight: 10
    },
    mustBePremiumText: {
      fontSize: 12
    },
    mustBePremiumLink: {
      textDecoration: 'underline',
      cursor: 'pointer'
    }
  };

  const { isPremium, options, setOption, openWhyPremiumDialog } = props;

  return (
    <div style={styles.optionsBody}>
      <div style={styles.optionsGroupHeader}>Ignore</div>
      <div style={styles.optionsGroup}>
        <div style={styles.checkboxGroup}>
          <OptionsCheckbox
            checked={options['ica']}
            option="ica"
            setOption={setOption}
            columns={1}
          />
          <OptionsCheckbox
            checked={options['ils']}
            option="ils"
            setOption={setOption}
            columns={1}
          />
          <OptionsCheckbox
            checked={options['ico']}
            option="ico"
            setOption={setOption}
            columns={1}
          />
        </div>
      </div>
      <ReactTooltip id="ignore-whitespace-tooltip" />
      <div
        style={Object.assign({}, styles.optionsGroupHeader, { textDecoration: 'underline' })}
        data-tip={optionsTooltips['ignore-whitespace']}
        data-for="ignore-whitespace-tooltip"
        data-html
      >
        Ignore whitespace in lines
      </div>
      <div style={styles.optionsGroup}>
        <div style={styles.checkboxGroup}>
          <OptionsCheckbox
            checked={options['iwa']}
            option="iwa"
            setOption={setOption}
            columns={1}
          />
          <OptionsCheckbox
            checked={options['iwl']}
            option="iwl"
            setOption={setOption}
            columns={1}
          />
          <OptionsCheckbox
            checked={options['iwt']}
            option="iwt"
            setOption={setOption}
            columns={1}
          />
          <OptionsCheckbox
            checked={options['iwc']}
            option="iwc"
            setOption={setOption}
            columns={1}
          />
        </div>
      </div>
      <div style={styles.optionsGroupHeader}>Ignore lines</div>
      <div style={styles.optionsGroup}>
        <div style={styles.checkboxGroup}>
          <OptionsCheckbox
            checked={options['ibl']}
            option="ibl"
            setOption={setOption}
            columns={1}
          />
          <OptionsCheckbox
            checked={options['ibe']}
            option="ibe"
            setOption={setOption}
            columns={3}
            disabled={!isPremium}
          />
        </div>
        <div style={styles.checkboxTextField}>
          <OptionsTextfield
            value={options['ilm']}
            option="ilm"
            setOption={setOption}
            disabled={!isPremium}
            error={validateRegex(options['ilm'])}
          />
        </div>
        <div style={styles.checkboxTextField}>
          <OptionsTextfield
            value={options['ilr']}
            option="ilr"
            setOption={setOption}
            disabled={!isPremium}
            error={validateRange(options['ilr'])}
          />
        </div>
      </div>
      <div style={styles.optionsGroupHeader}>Ignore parts of each lines</div>
      <div style={styles.optionsGroup}>
        <div style={styles.checkboxTextField}>
          <OptionsTextfield
            value={options['ipm']}
            option="ipm"
            setOption={setOption}
            disabled={!isPremium}
            error={validateRegex(options['ipm'])}
          />
        </div>
        <div style={styles.checkboxTextField}>
          <OptionsTextfield
            value={options['icr']}
            option="icr"
            setOption={setOption}
            disabled={!isPremium}
            error={validateRange(options['icr'])}
          />
        </div>
      </div>
      <div style={styles.optionsGroupHeader}>Binary comparison</div>
      <div style={styles.optionsGroup}>
        <div style={styles.checkboxGroup}>
          <OptionsCheckbox
            checked={options['fbc']}
            option="fbc"
            setOption={setOption}
            columns={4}
            disabled={!isPremium}
          />
        </div>
      </div>

      <div
        style={Object.assign({}, styles.mustBePremiumContainer, {
          display: isPremium ? 'none' : 'flex'
        })}
      >
        <Icon style={styles.mustBePremiumIcon}>star</Icon>
        <span style={styles.mustBePremiumText}>
          <span>This feature is only available for&nbsp;</span>
          <a href="#why-premium" onClick={openWhyPremiumDialog} style={styles.mustBePremiumLink}>
            premium
          </a>
          <span>&nbsp;users.</span>
        </span>
      </div>
    </div>
  );
};

CompareOptions.propTypes = {
  isPremium: bool,
  options: object,
  setOption: func,
  openWhyPremiumDialog: func,
  theme: object
};

export default withTheme()(CompareOptions);
