import 'babel-polyfill'; // eslint-disable-line
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import amplitude from 'amplitude-js';
import { AmplitudeProvider } from '@amplitude/react-amplitude';
import CssBaseline from '@material-ui/core/CssBaseline';

import App from './containers/App';
import ConnectedThemeProvider from './containers/ConnectedThemeProvider';
import { isBrowserUnsupported } from './util/common';
import configureStore, { history } from './store/configureStore';
import { AMPLITUDE_KEY } from './util/constants';
import diffnowLogo from './diffnow.png';
import './styles/index.css';
import './styles/fonts.css';

const store = configureStore({});

if (isBrowserUnsupported()) {
  render(
    <div style={{ margin: 20, fontSize: 24 }}>
      <img src={diffnowLogo} alt="DiffNow" />
      <div>
        This browser does not support DiffNow. Please upgrade your browser to the latest Firefox,
        Chrome, Microsoft Edge, or Internet Explorer.
      </div>
    </div>,
    document.getElementById('root')
  );
} else {
  render(
    <Provider store={store}>
      <ConnectedThemeProvider>
        <CssBaseline />
        <ConnectedRouter history={history}>
          <AmplitudeProvider amplitudeInstance={amplitude.getInstance()} apiKey={AMPLITUDE_KEY}>
            <App history={history} />
          </AmplitudeProvider>
        </ConnectedRouter>
      </ConnectedThemeProvider>
    </Provider>,
    document.getElementById('root')
  );
}
