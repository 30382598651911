import React, { Component } from 'react';
import { func, string, object, number } from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { Amplitude } from '@amplitude/react-amplitude';

import { COMPARE_URLS, COMPARE_PANE_TRANSITION_MS } from '../util/constants';
import CompareButton from '../components/comparison/CompareButton';
import UrlInput from '../components/comparison/UrlInput';

function mapStateToProps(state) {
  return {
    theme: state.global.theme,
    user: state.global.user,
    clientId: state.global.clientId,
    options: state.global.options,
    comparisonCount: state.global.comparisonCount,
    comparisonStartDate: state.global.comparisonStartDate,
    configuration: state.global.configuration,
    firstUrl: state.global.firstUrl,
    secondUrl: state.global.secondUrl,
    collapsedTabIndex: state.global.collapsedTabIndex
  };
}

class CompareUrls extends Component {
  static propTypes = {
    theme: string,
    clientId: string,
    onCompare: func,
    onSetCollapsedTabIndex: func,
    options: object,
    user: object,
    comparisonCount: number,
    configuration: object,
    firstUrl: string,
    secondUrl: string,
    collapsedTabIndex: number,
    openWhyPremiumDialog: func
  };

  constructor(props) {
    super(props);

    const { firstUrl, secondUrl } = this.props;

    this.state = { open: false, firstUrl, secondUrl };
  }

  static getDerivedStateFromProps = (props, state) => ({
    ...state,
    open: props.collapsedTabIndex === null
  });

  componentDidMount = () => {
    this.executeComparisonFromUrlParams();
  };

  componentDidUpdate = prevProps => {
    const { configuration, comparisonCount } = this.props;

    if (
      configuration !== prevProps.configuration ||
      (comparisonCount !== prevProps.comparisonCount && isUndefined(prevProps.comparisonCount))
    ) {
      this.executeComparisonFromUrlParams();
    }
  };

  executeComparisonFromUrlParams = () => {
    const { onSetCollapsedTabIndex, configuration, comparisonCount } = this.props;
    const params = queryString.parse(window.location.search);

    onSetCollapsedTabIndex(null);

    if (params.url1 && params.url2 && !isEmpty(configuration) && !isUndefined(comparisonCount)) {
      if (params.demo === '1') {
        this.setState({ firstUrl: '', secondUrl: '' }, () => {
          this.typeAndCompareDemoUrls(params.url1, params.url2);
        });
      } else {
        this.enterAndCompareUrls(params.url1, params.url2);
      }
    }
  };

  compareUrls = async () => {
    this.props.onCompare(COMPARE_URLS, this.getComparisonPayload());
  };

  getComparisonPayload = () => {
    const { user, clientId, options, theme } = this.props;
    const { firstUrl, secondUrl } = this.state;

    const sessionToken = user ? user.sessionToken : null;

    return {
      title1: firstUrl,
      title2: secondUrl,
      url1: firstUrl,
      url2: secondUrl,
      sessionId: clientId,
      sessionToken,
      optionsJson: options,
      darkMode: theme === 'dark'
    };
  };

  typeAndCompareDemoUrls = (url1, url2) => {
    setTimeout(() => {
      this.setState(
        ({ firstUrl, secondUrl }) => ({
          firstUrl: firstUrl + (url1[0] || ''),
          secondUrl: secondUrl + (url1.length ? '' : url2[0])
        }),
        () => {
          if (url1.length === 0 && url2.length === 1) {
            this.compareUrls();
          } else if (url1.length !== 0) {
            this.typeAndCompareDemoUrls(url1.slice(1), url2);
          } else {
            this.typeAndCompareDemoUrls(url1, url2.slice(1));
          }
        }
      );
    }, 20);
  };

  enterAndCompareUrls = (url1, url2) => {
    this.setState(
      {
        firstUrl: url1,
        secondUrl: url2
      },
      this.compareUrls
    );
  };

  isValidUrl = url => {
    const regExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    return regExp.test(url) || isEmpty(url);
  };

  get styles() {
    return {
      outer: {
        padding: 20,
        paddingBottom: 12
      },
      contents: {
        display: 'flex',
        flexDirection: 'column'
      },
      topLabel: {
        fontSize: 14,
        color: '#777777'
      },
      bottomLabel: {
        fontSize: 12,
        color: '#999999',
        marginTop: 16
      },
      compareButtonContainer: {
        position: 'relative',
        display: 'flex',
        marginTop: 15,
        justifyContent: 'center',
        height: 50
      }
    };
  }

  render() {
    const { firstUrl, secondUrl, open } = this.state;
    const { openWhyPremiumDialog } = this.props;

    const firstUrlError = this.isValidUrl(firstUrl) ? null : 'This URL is not valid.';
    const secondUrlError = this.isValidUrl(secondUrl) ? null : 'This URL is not valid.';
    const isValidState =
      !firstUrlError && !secondUrlError && !isEmpty(firstUrl) && !isEmpty(secondUrl);

    return (
      <div>
        <Paper style={this.state.open ? this.styles.outer : {}}>
          <Collapse
            in={open}
            timeout={COMPARE_PANE_TRANSITION_MS}
            style={this.state.open ? {} : { visibility: 'hidden' }}
          >
            <div style={this.styles.contents}>
              <div style={this.styles.topLabel}>Enter the URLs you want to compare below.</div>

              <UrlInput
                error={firstUrlError}
                id="url-1"
                hintText="Enter the first URL here"
                value={firstUrl}
                onChange={e => this.setState({ firstUrl: e.target.value })}
              />

              <UrlInput
                error={secondUrlError}
                id="url-2"
                hintText="Enter the second URL here"
                value={secondUrl}
                onChange={e => this.setState({ secondUrl: e.target.value })}
              />

              <div style={this.styles.bottomLabel}>
                First 2048 KB will be compared (8192 KB for{' '}
                <a href="#why-premium" onClick={openWhyPremiumDialog}>
                  premium users
                </a>
                )
              </div>
            </div>

            <div style={this.styles.compareButtonContainer}>
              <Amplitude>
                {({ logEvent }) => (
                  <CompareButton
                    disabled={!isValidState}
                    onClick={() => {
                      this.compareUrls();
                      logEvent('START_COMPARISON', { type: 'URLS' });
                    }}
                  />
                )}
              </Amplitude>
            </div>
          </Collapse>
        </Paper>
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(CompareUrls));
