let ReactGA, currentLocation;
if (inBrowser()) {
  ReactGA = require('react-ga');
  ReactGA.initialize('UA-363598-3');
}

export function inBrowser() {
  return !(
    typeof document === 'undefined' ||
    (window.process && window.process.title.includes('node')) ||
    (window.process && window.process.title.includes('test')) ||
    (window.process && window.process.env.JEST_WORKER_ID !== undefined)
  );
}

export function logAnalytics() {
  if (inBrowser() && window.location.pathname !== currentLocation) {
    currentLocation = window.location.pathname;
    ReactGA.set({ page: currentLocation });
    ReactGA.pageview(currentLocation);
  }
}

export function transformHistory(history, func) {
  if (history && history.location) {
    const currentPath = history.location.pathname;
    const newPath = func(currentPath === '/' ? '/compareClips' : currentPath);
    history.push(newPath);
  }
}

export function getHash(history) {
  return history && history.location.hash.split('#')[1];
}

export function setHash(history, hash) {
  transformHistory(history, path => `${path}#${hash}`);
}

export function areAdsInvisible() {
  return !window.adsLoaded;
}

// https://stackoverflow.com/a/38241481
export function getOS() {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = platform;

  if (macosPlatforms.includes(platform)) {
    os = 'Mac OS';
  } else if (iosPlatforms.includes(platform)) {
    os = 'iOS';
  } else if (windowsPlatforms.includes(platform)) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}

export function canUseExamDiff() {
  return getOS() === 'Windows';
}
