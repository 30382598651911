import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  dialogPaper: {
    maxHeight: 380,
    width: 420
  }
};

class WordWrapWarningDialog extends Component {
  static propTypes = {
    open: bool,
    onForceWordWrap: func,
    onKeepIgnoreOptions: func,
    classes: object
  };

  get actions() {
    const { onForceWordWrap, onKeepIgnoreOptions } = this.props;
    return (
      <div>
        <Button variant="outlined" onClick={onForceWordWrap} style={{ marginRight: 10 }}>
          Enable Word Wrap
        </Button>
        <Button variant="outlined" onClick={onKeepIgnoreOptions} style={{ marginRight: 10 }}>
          Enable Ignore Options
        </Button>
      </div>
    );
  }

  render() {
    const { open, classes } = this.props;
    return (
      <Dialog open={open} classes={{ paper: classes.dialogPaper }}>
        <DialogContent>
          The Word Wrap feature cannot be used together with <b>Ignore</b> options that use regular{' '}
          expressions to ignore line parts (Ignore Parts of Each Line and Ignore Comments). Do you
          want to enable Word Wrap and disable these <b>Ignore</b> options, or to enable the{' '}
          <b>Ignore</b> options, and disable Word Wrap?
        </DialogContent>
        <DialogActions>{this.actions}</DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(WordWrapWarningDialog);
