import axios from 'axios';

import { DIFFNOW_API_URL } from './constants';

const UTIL_URL = `${DIFFNOW_API_URL}/util`;

export async function getConfiguration() {
  try {
    const response = await axios.get(`${UTIL_URL}/getConfiguration`);
    return response.data;
  } catch (e) {
    console.error(e);
    return {};
  }
}

export function getChangeLog(onSuccess, onError) {
  axios
    .get(`${UTIL_URL}/getChangeLog`)
    .then(response => {
      onSuccess(response.data.changeLog);
    })
    .catch(err => {
      if (err.response) {
        onError(err.response.data);
      } else {
        onError(err.message);
      }
    });
}
