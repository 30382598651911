const defaultState = {
  theme: 'light', // light | dark
  premiumSource: 'accountsbar-button',
  user: undefined, // null = logged out, undefined = unknown yet if logged in or out
  renderId: 0,
  status: {
    message: '',
    type: ''
  },
  isComparing: false,
  isTruncated: false,
  isReportReady: false,
  reportId: undefined,
  durationKey: undefined,
  examDiffUri: null,
  optionsProfiles: [
    {
      options_name: 'Default Profile',
      current_profile: 1
    }
  ],
  optionsLoaded: false,
  options: {},
  newOptions: {},
  defaultOptions: {},
  comparisonCount: undefined,
  comparisonStartDate: null,
  configuration: {},
  lastComparedTabIndex: null,
  collapsedTabIndex: null,

  firstTitle: '',
  secondTitle: '',

  firstClipTitle: '',
  firstClip: '',
  secondClipTitle: '',
  secondClip: '',
  docType: '',

  firstUrlTitle: '',
  firstUrl: '',
  secondUrlTitle: '',
  secondUrl: '',

  firstFileTitle: '',
  firstFileStatus: '',
  firstFileName: '',
  firstFileUploadedName: 'not-uploaded',
  secondFileTitle: '',
  secondFileStatus: '',
  secondFileName: '',
  secondFileUploadedName: 'not-uploaded'
};

export default defaultState;
