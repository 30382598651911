import React from 'react';
import { func, bool, object } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const styles = {
  root: {
    zIndex: 1500
  }
};

const RecomparePromptDialog = ({ onRecompare, handleClose, open, classes }) => (
  <Dialog open={open} onClose={handleClose} className={classes.root}>
    <DialogTitle>Options Changed</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Some comparison options have changed. Would you like to re-compare now?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>No</Button>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => {
          onRecompare();
          handleClose();
        }}
      >
        Yes
      </Button>
    </DialogActions>
  </Dialog>
);

RecomparePromptDialog.propTypes = {
  onRecompare: func,
  handleClose: func,
  open: bool,
  classes: object
};

export default withStyles(styles)(RecomparePromptDialog);
