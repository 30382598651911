import React from 'react';
import { func } from 'prop-types';

import { DONATE_URL, PRESTOSOFT_URL } from '../util/constants';

const Footer = ({ openTermsOfServiceDialog, openContactDialog, openChangeLogDialog }) => (
  <div
    style={{
      padding: 12,
      marginTop: 15,
      display: 'flex',
      justifyContent: 'center',
      fontSize: 12,
      color: '#777'
    }}
  >
    <div>Copyright © 2011-2023</div>
    <a
      style={{ marginLeft: 5, textDecoration: 'underline', cursor: 'pointer', color: '#777777' }}
      target="_blank"
      rel="noopener noreferrer"
      href={PRESTOSOFT_URL}
    >
      PrestoSoft LLC.
    </a>
    <div style={{ marginLeft: 5 }}>All rights reserved.</div>
    <div
      style={{ marginLeft: 5, textDecoration: 'underline', cursor: 'pointer' }}
      onClick={openTermsOfServiceDialog}
    >
      Terms of Service
    </div>
    <div>.</div>
    <div
      style={{ marginLeft: 5, textDecoration: 'underline', cursor: 'pointer' }}
      onClick={openContactDialog}
    >
      Contact us
    </div>
    <div>.</div>
    <div
      style={{ marginLeft: 5, textDecoration: 'underline', cursor: 'pointer' }}
      onClick={openChangeLogDialog}
    >
      Changelog
    </div>
    <div>.</div>
    <a
      style={{ marginLeft: 5, textDecoration: 'underline', cursor: 'pointer', color: '#777777' }}
      target="_blank"
      rel="noopener noreferrer"
      href={DONATE_URL}
    >
      Donate
    </a>
    <div>.</div>
  </div>
);

Footer.propTypes = {
  openTermsOfServiceDialog: func,
  openContactDialog: func,
  openChangeLogDialog: func
};

export default Footer;
