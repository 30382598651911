import React from 'react';
import reactStringReplace from 'react-string-replace';
import isString from 'lodash/isString';

const replaceOpenWhyPremium = (rawStatus, openWhyPremiumDialog) => {
  if (!isString(rawStatus) || !rawStatus.includes('<OpenWhyPremium')) {
    return rawStatus;
  }

  const sourceMatch = rawStatus.match(/<OpenWhyPremium source="(.*?)">(.*?)<\/OpenWhyPremium>/);
  const source = sourceMatch && sourceMatch[1];

  return reactStringReplace(
    rawStatus,
    /<OpenWhyPremium source=".*?">(.*?)<\/OpenWhyPremium>/,
    (match, i) => (
      <a href="#why-premium" onClick={() => openWhyPremiumDialog(source)} key={i}>
        {match}
      </a>
    )
  );
};

const replaceOpenOptions = (rawStatus, openOptionsDialog) => {
  if (!isString(rawStatus) || !rawStatus.includes('<OpenOptions')) {
    return rawStatus;
  }

  return reactStringReplace(rawStatus, /<OpenOptions>(.*?)<\/OpenOptions>/, (match, i) => (
    <a href="#options" onClick={openOptionsDialog} key={i}>
      {match}
    </a>
  ));
};

const replaceATag = rawStatus => {
  if (!isString(rawStatus) || !rawStatus.includes('<a ')) {
    return rawStatus;
  }

  const hrefMatch = rawStatus.match(/<a href="(.*?)">(.*?)<\/a>/);
  const href = hrefMatch && hrefMatch[1];

  return reactStringReplace(rawStatus, /<a .*?>(.*?)<\/a>/, (match, i) => (
    <a href={href} target="_blank" rel="noopener noreferrer" key={i}>
      {match}
    </a>
  ));
};

export const replaceTags = (rawStatus, openWhyPremiumDialog, openOptionsDialog) => {
  const statusWithoutATag = replaceATag(rawStatus);

  let statusWithoutOpenOptions;
  if (Array.isArray(statusWithoutATag)) {
    statusWithoutOpenOptions = statusWithoutATag.map(status =>
      replaceOpenOptions(status, openOptionsDialog)
    );
  } else {
    statusWithoutOpenOptions = replaceOpenOptions(statusWithoutATag, openOptionsDialog);
  }

  let statusWithoutOpenWhyPremium;
  if (Array.isArray(statusWithoutOpenOptions)) {
    statusWithoutOpenWhyPremium = statusWithoutOpenOptions.map(status =>
      replaceOpenWhyPremium(status, openWhyPremiumDialog)
    );
  } else {
    statusWithoutOpenWhyPremium = replaceOpenWhyPremium(
      statusWithoutOpenOptions,
      openWhyPremiumDialog
    );
  }

  return statusWithoutOpenWhyPremium;
};
