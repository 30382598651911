import React from 'react';
import { func } from 'prop-types';
import noop from 'lodash/noop';
import Paper from '@material-ui/core/Paper';

import { adBlockMessage } from '../util/status';
import { replaceTags } from '../util/messages';

const AdBlockMessage = ({ openWhyPremiumDialog }) => (
  <Paper
    className="adblock-message"
    style={{
      display: 'flex',
      marginTop: 20,
      marginBottom: 20,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#EF5350',
      minHeight: 100
    }}
  >
    <div
      style={{
        padding: 10,
        fontSize: 18,
        color: '#EEEEEE',
        fontWeight: 400
      }}
    >
      {replaceTags(adBlockMessage, openWhyPremiumDialog, noop)}
    </div>
  </Paper>
);

AdBlockMessage.propTypes = {
  openWhyPremiumDialog: func
};

export default AdBlockMessage;
