import React from 'react';
import Media from 'react-media';
import { string, func } from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { docTypes } from '../../util/constants';

const DocTypeSelector = ({ docType, onSelect }) => (
  <Media
    queries={{
      small: '(max-width: 749px)',
      large: '(min-width: 750px)'
    }}
  >
    {matches => (
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <FormControl>
          <InputLabel shrink htmlFor="doctype-picker">
            {matches.large ? 'Document Type' : 'Doc Type'}
          </InputLabel>
          <Select
            displayEmpty
            value={docType}
            onChange={e => onSelect(e.target.value)}
            style={{ width: matches.large ? 200 : 100 }}
            inputProps={{
              id: 'doctype-picker'
            }}
          >
            {Object.keys(docTypes)
              .sort()
              .map((key, index) => (
                <MenuItem key={index} value={key}>
                  {docTypes[key]}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    )}
  </Media>
);

DocTypeSelector.propTypes = {
  docType: string,
  onSelect: func
};

export default DocTypeSelector;
